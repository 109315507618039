import React, { useEffect, useState } from "react";
import { getResDataFirebase } from "../../Utils/firebaseResearch";
import { useNavigate } from "react-router-dom";

export const WordAnalysis = () => {
  const navigate = useNavigate();
  const [resData, setResData] = useState([]);

  useEffect(() => {
    getResDataFirebase().then(function (result) {
      setResData(result);
    });
  }, []);

  return (
    <div>
      <h1>Data Analysis</h1>
      <button
        className="defaultButton"
        onClick={() => navigate("/globalanalysis")}
      >
        Global Analysis
      </button>
      {resData.map((u) => (
        <button
          key={u.id}
          className="defaultButton"
          onClick={() =>
            navigate("/useranalysis", {
              state: {
                userId: u.id,
              },
            })
          }
        >
          {u.id}
        </button>
      ))}
    </div>
  );
};
