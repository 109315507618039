export const ANEWBr = [
  // Eu me sinto... (60 palavras, 30 negativas, 30 positivas)
  "amável",
  "radiante",
  "rejeitado",
  "amigável",
  "deprimente",
  "infeliz",
  "mal",
  "incomodado",
  "desamparado",
  "deprimido",
  "satisfeito",
  "abandonado",
  "gentil",
  "desanimado",
  "perturbado",
  "triunfante",
  "alegre",
  "cansado",
  "amado",
  "honesto",
  "campeão",
  "derrotado",
  "tranquilo",
  "abençoado",
  "aterrorizado",
  "adorável",
  "agradável",
  "feliz",
  "menosprezado",
  "angustiado",
  "frustrado",
  "divertido",
  "triste",
  "bonito",
  "agradecido",
  "abalado",
  "viciado",
  "livre",
  "querido",
  "desleal",
  "cruel",
  "doente",
  "romântico",
  "criminoso",
  "leal",
  "vândalo",
  "bom",
  "festivo",
  "malcheiroso",
  "tóxico",
  "podre",
  "útil",
  "novo",
  "sábio",
  "bravo",
  "suave",
  "arrumado",
  "ridículo",
  "digno",
  "sobrecarregado",
];

export const ANEWBrSinAnt = [
  {
    sample: "amável",
    sin: "adorável",
    ant: "abominável",
  },
  {
    sample: "radiante",
    sin: "brilhante",
    ant: "apagado",
  },
  {
    sample: "rejeitado",
    sin: "abandonado",
    ant: "aceito",
  },
  {
    sample: "amigável",
    sin: "amistoso",
    ant: "antipático",
  },
  {
    sample: "deprimente",
    sin: "angustiante",
    ant: "enobrecido",
  },
  {
    sample: "infeliz",
    sin: "amargurado",
    ant: "alegre",
  },
  {
    sample: "mal",
    sin: "amargo",
    ant: "bem",
  },
  {
    sample: "incomodado",
    sin: "desconfortável",
    ant: "confortável",
  },
  {
    sample: "desamparado",
    sin: "abandonado",
    ant: "protegido",
  },
  {
    sample: "deprimido",
    sin: "abatido",
    ant: "alegre",
  },
  {
    sample: "satisfeito",
    sin: "contente",
    ant: "aborrecido",
  },
  {
    sample: "abandonado",
    sin: "desamparado",
    ant: "acolhido",
  },
  {
    sample: "gentil",
    sin: "amável",
    ant: "antipático",
  },
  {
    sample: "desanimado",
    sin: "abatido",
    ant: "animado",
  },
  {
    sample: "perturbado",
    sin: "irritado",
    ant: "calmo",
  },
  {
    sample: "triunfante",
    sin: "glorioso",
    ant: "humilhado",
  },
  {
    sample: "alegre",
    sin: "contente",
    ant: "infeliz",
  },
  {
    sample: "cansado",
    sin: "fatigado",
    ant: "disposto",
  },
  {
    sample: "amado",
    sin: "querido",
    ant: "odiado",
  },
  {
    sample: "honesto",
    sin: "honrado",
    ant: "trapaceiro",
  },
  {
    sample: "campeão",
    sin: "vencedor",
    ant: "vencido",
  },
  {
    sample: "derrotado",
    sin: "abatido",
    ant: "vitorioso",
  },
  {
    sample: "tranquilo",
    sin: "calmo",
    ant: "desequilibrado",
  },
  {
    sample: "abençoado",
    sin: "predestinado",
    ant: "desgraçado",
  },
  {
    sample: "aterrorizado",
    sin: "apavorado",
    ant: "sereno",
  },
  {
    sample: "adorável",
    sin: "fascinante",
    ant: "antipático",
  },
  {
    sample: "agradável",
    sin: "simpático",
    ant: "antipático",
  },
  {
    sample: "feliz",
    sin: "contente",
    ant: "aborrecido",
  },
  {
    sample: "menosprezado",
    sin: "humilhado",
    ant: "apreciado",
  },
  {
    sample: "angustiado",
    sin: "aflito",
    ant: "tranquilo",
  },
  {
    sample: "frustrado",
    sin: "fracassado",
    ant: "triunfante",
  },
  {
    sample: "divertido",
    sin: "jovial",
    ant: "taciturno",
  },
  {
    sample: "triste",
    sin: "péssimo",
    ant: "ótimo",
  },
  {
    sample: "bonito",
    sin: "belo",
    ant: "feio",
  },
  {
    sample: "agradecido",
    sin: "grato",
    ant: "ingrato",
  },
  {
    sample: "abalado",
    sin: "assustado",
    ant: "calmo",
  },
  {
    sample: "viciado",
    sin: "descuidado",
    ant: "assertivo",
  },
  {
    sample: "livre",
    sin: "independente",
    ant: "submisso",
  },
  {
    sample: "querido",
    sin: "amado",
    ant: "detestado",
  },
  {
    sample: "desleal",
    sin: "falso",
    ant: "verdadeiro",
  },
  {
    sample: "cruel",
    sin: "desumano",
    ant: "bondoso",
  },
  {
    sample: "doente",
    sin: "indisposto",
    ant: "vigoroso",
  },
  {
    sample: "romântico",
    sin: "sonhador",
    ant: "desencantado",
  },
  {
    sample: "criminoso",
    sin: "culpado",
    ant: "inocente",
  },
  {
    sample: "leal",
    sin: "correto",
    ant: "falso",
  },
  {
    sample: "vândalo",
    sin: "desrespeitoso",
    ant: "comportado",
  },
  {
    sample: "bom",
    sin: "adequado",
    ant: "inconveniente",
  },
  {
    sample: "festivo",
    sin: "alegre",
    ant: "tedioso",
  },
  {
    sample: "malcheiroso",
    sin: "fétido",
    ant: "perfumado",
  },
  {
    sample: "tóxico",
    sin: "venenoso",
    ant: "benéfico",
  },
  {
    sample: "útil",
    sin: "produtivo",
    ant: "infrutifero",
  },
  {
    sample: "novo",
    sin: "jovem",
    ant: "velho",
  },
  {
    sample: "sábio",
    sin: "sensato",
    ant: "descuidado",
  },
  {
    sample: "bravo",
    sin: "irritado",
    ant: "tranquilo",
  },
  {
    sample: "suave",
    sin: "sossegado",
    ant: "tumultuado",
  },
  {
    sample: "arrumado",
    sin: "organizado",
    ant: "largado",
  },
  {
    sample: "ridículo",
    sin: "cafona",
    ant: "elegante",
  },
  {
    sample: "digno",
    sin: "honrado",
    ant: "desmerecedor",
  },
  {
    sample: "sobrecarregado",
    sin: "atormentado",
    ant: "aliviado",
  },
];

export const ANEWBrSinAntTrial = [
  {
    sample: "amável",
    sin: "adorável",
    ant: "abominável",
    options: ["rejeitado", "jovial", "sonhador", "aflito"],
  },
  {
    sample: "radiante",
    sin: "brilhante",
    ant: "apagado",
    options: ["vândalo", "fétido", "útil", "elegante"],
  },
  {
    sample: "rejeitado",
    sin: "abandonado",
    ant: "aceito",
    options: ["organizado", "submisso", "sereno", "tumultuado"],
  },
  {
    sample: "amigável",
    sin: "amistoso",
    ant: "antipático",
    options: ["tranquilo", "descuidado", "aceito", "contente"],
  },
  {
    sample: "deprimente",
    sin: "angustiante",
    ant: "enobrecido",
    options: ["romântico", "desconfortável", "protegido", "odiado"],
  },
  {
    sample: "infeliz",
    sin: "amargurado",
    ant: "alegre",
    options: ["feio", "ingrato", "tranquilo", "aliviado"],
  },
  {
    sample: "mal",
    sin: "amargo",
    ant: "bem",
    options: ["organizado", "submisso", "aborrecido", "aceito"],
  },
  {
    sample: "incomodado",
    sin: "desconfortável",
    ant: "confortável",
    options: ["amigável", "abandonado", "predestinado", "humilhado"],
  },
  {
    sample: "desamparado",
    sin: "abandonado",
    ant: "protegido",
    options: ["abominável", "brilhante", "amistoso", "infeliz"],
  },
  {
    sample: "deprimido",
    sin: "abatido",
    ant: "alegre",
    options: ["reconhecido", "desequilibrado", "belo", "descuidado"],
  },
  {
    sample: "satisfeito",
    sin: "contente",
    ant: "aborrecido",
    options: ["elegante", "digno", "descuidado", "infrutifero"],
  },
  {
    sample: "abandonado",
    sin: "desamparado",
    ant: "acolhido",
    options: ["aborrecido", "alegre", "bem", "antipático"],
  },
  {
    sample: "gentil",
    sin: "amável",
    ant: "antipático",
    options: ["irritado", "confortável", "deprimido", "humilhado"],
  },
  {
    sample: "desanimado",
    sin: "abatido",
    ant: "animado",
    options: ["calmo", "aceito", "apavorado", "assustado"],
  },
  {
    sample: "perturbado",
    sin: "irritado",
    ant: "calmo",
    options: ["bonito", "abominável", "protegido", "vencido"],
  },
  {
    sample: "triunfante",
    sin: "glorioso",
    ant: "humilhado",
    options: ["útil", "novo", "descuidado", "cafona"],
  },
  {
    sample: "alegre",
    sin: "contente",
    ant: "infeliz",
    options: ["digno", "aliviado", "tumultuado", "velho"],
  },
  {
    sample: "cansado",
    sin: "fatigado",
    ant: "disposto",
    options: ["bravo", "sábio", "venenoso", "perfumado"],
  },
  {
    sample: "amado",
    sin: "querido",
    ant: "odiado",
    options: ["falso", "viciado", "agradecido", "belo"],
  },
  {
    sample: "honesto",
    sin: "honrado",
    ant: "desonesto",
    options: ["aliviado", "animado", "ridículo", "irritado"],
  },
  {
    sample: "campeão",
    sin: "vencedor",
    ant: "vencido",
    options: ["aceito", "enobrecido", "abandonado", "antipático"],
  },
  {
    sample: "derrotado",
    sin: "abatido",
    ant: "vitorioso",
    options: ["desequilibrado", "inocente", "cruel", "sonhador"],
  },
  {
    sample: "tranquilo",
    sin: "calmo",
    ant: "desequilibrado",
    options: ["adorável", "apagado", "protegido", "gentil"],
  },
  {
    sample: "abençoado",
    sin: "predestinado",
    ant: "desgraçado",
    options: ["triunfante", "fatigado", "honesto", "feio"],
  },
  {
    sample: "aterrorizado",
    sin: "apavorado",
    ant: "sereno",
    options: ["assertivo", "independente", "querido", "desumano"],
  },
  {
    sample: "adorável",
    sin: "fascinante",
    ant: "antipático",
    options: ["indisposto", "desencantado", "inocente", "útil"],
  },
  {
    sample: "agradável",
    sin: "simpático",
    ant: "antipático",
    options: ["produtivo", "jovem", "descuidado", "tumultuado"],
  },
  {
    sample: "feliz",
    sin: "contente",
    ant: "aborrecido",
    options: ["acolhido", "ingrato", "viciado", "livre"],
  },
  {
    sample: "menosprezado",
    sin: "humilhado",
    ant: "apreciado",
    options: ["desleal", "cruel", "correto", "alegre"],
  },
  {
    sample: "angustiado",
    sin: "aflito",
    ant: "tranquilo",
    options: ["malcheiroso", "tóxico", "novo", "sábio"],
  },
  {
    sample: "frustrado",
    sin: "fracassado",
    ant: "triunfante",
    options: ["organizado", "elegante", "feio", "descuidado"],
  },
  {
    sample: "divertido",
    sin: "jovial",
    ant: "taciturno",
    options: ["agradecido", "calmo", "independente", "falso"],
  },
  {
    sample: "triste",
    sin: "péssimo",
    ant: "ótimo",
    options: ["desumano", "romântico", "desrespeitoso", "perfumado"],
  },
  {
    sample: "bonito",
    sin: "belo",
    ant: "feio",
    options: ["tedioso", "deprimente", "adorável", "contente"],
  },
  {
    sample: "agradecido",
    sin: "reconhecido",
    ant: "ingrato",
    options: ["venenoso", "jovem", "tranquilo", "largado"],
  },
  {
    sample: "abalado",
    sin: "assustado",
    ant: "calmo",
    options: ["digno", "sábio", "velho", "infrutifero"],
  },
  {
    sample: "viciado",
    sin: "descuidado",
    ant: "assertivo",
    options: ["brilhante", "aceito", "desconfortável", "abatido"],
  },
  {
    sample: "livre",
    sin: "independente",
    ant: "submisso",
    options: ["apagado", "rejeitado", "enobrecido", "confortável"],
  },
  {
    sample: "querido",
    sin: "amado",
    ant: "detestado",
    options: ["agradecido", "assustado", "festivo", "descuidado"],
  },
  {
    sample: "desleal",
    sin: "falso",
    ant: "verdadeiro",
    options: ["produtivo", "bravo", "suave", "largado"],
  },
  {
    sample: "cruel",
    sin: "desumano",
    ant: "bondoso",
    options: ["apagado", "aceito", "infeliz", "confortável"],
  },
  {
    sample: "doente",
    sin: "indisposto",
    ant: "vigoroso",
    options: ["acolhido", "odiado", "simpático", "ingrato"],
  },
  {
    sample: "romântico",
    sin: "sonhador",
    ant: "desencantado",
    options: ["assertivo", "livre", "desleal", "doente"],
  },
  {
    sample: "criminoso",
    sin: "culpado",
    ant: "inocente",
    options: ["sonhador", "festivo", "angustiante", "amargurado"],
  },
  {
    sample: "leal",
    sin: "correto",
    ant: "falso",
    options: ["desconfortável", "protegido", "aflito", "divertido"],
  },
  {
    sample: "vândalo",
    sin: "desrespeitoso",
    ant: "comportado",
    options: ["triste", "agradecido", "calmo", "submisso"],
  },
  {
    sample: "bom",
    sin: "adequado",
    ant: "inconveniente",
    options: ["desencantado", "livre", "jovial", "cafona"],
  },
  {
    sample: "festivo",
    sin: "alegre",
    ant: "tedioso",
    options: ["abandonado", "confortável", "sereno", "feio"],
  },
  {
    sample: "malcheiroso",
    sin: "fétido",
    ant: "perfumado",
    options: ["deprimente", "infeliz", "bem", "satisfeito"],
  },
  {
    sample: "tóxico",
    sin: "venenoso",
    ant: "benéfico",
    options: ["alegre", "radiante", "humilhado", "velho"],
  },
  {
    sample: "útil",
    sin: "produtivo",
    ant: "infrutifero",
    options: ["aceito", "amistoso", "incomodado", "deprimido"],
  },
  {
    sample: "novo",
    sin: "jovem",
    ant: "velho",
    options: ["gentil", "perturbado", "triunfante", "amado"],
  },
  {
    sample: "sábio",
    sin: "sensato",
    ant: "descuidado",
    options: ["infeliz", "querido", "apavorado", "feliz"],
  },
  {
    sample: "bravo",
    sin: "irritado",
    ant: "tranquilo",
    options: ["rejeitado", "enobrecido", "protegido", "desamparado"],
  },
  {
    sample: "suave",
    sin: "sossegado",
    ant: "tumultuado",
    options: ["animado", "disposto", "vencido", "ingrato"],
  },
  {
    sample: "arrumado",
    sin: "organizado",
    ant: "largado",
    options: ["calmo", "alegre", "mal", "apagado"],
  },
  {
    sample: "ridículo",
    sin: "cafona",
    ant: "elegante",
    options: ["amável", "aceito", "amargo", "abatido"],
  },
  {
    sample: "digno",
    sin: "honrado",
    ant: "desmerecedor",
    options: ["satisfeito", "gentil", "desanimado", "fatigado"],
  },
  {
    sample: "sobrecarregado",
    sin: "atormentado",
    ant: "aliviado",
    options: ["simpático", "divertido", "feio", "ingrato"],
  },
];

// List 1: VEK, CUG, POG, LEW
// List 2: JOM, ZID, FAV, REB
// List 3: banana, maçã, abacaxi, abacate

export const controlListTrial1 = [
  {
    sample: "VEK",
    context: "SIMILAR",
    options: ["CUG", "banana", "maçã"],
    correct: "CUG",
  },
  {
    sample: "VEK",
    context: "SIMILAR",
    options: ["CUG", "banana", "maçã", "FAV"],
    correct: "CUG",
  },
  {
    sample: "VEK",
    context: "SIMILAR",
    options: ["CUG", "banana", "maçã", "FAV", "REB"],
    correct: "CUG",
  },
  {
    sample: "JOM",
    context: "OPOSTO",
    options: ["VEK", "abacaxi", "maçã"],
    correct: "VEK",
  },
  {
    sample: "JOM",
    context: "OPOSTO",
    options: ["VEK", "abacaxi", "maçã", "FAV"],
    correct: "VEK",
  },
  {
    sample: "JOM",
    context: "OPOSTO",
    options: ["VEK", "abacaxi", "maçã", "FAV", "REB"],
    correct: "VEK",
  },
  {
    sample: "JOM",
    context: "SIMILAR",
    options: ["FAV", "abacate", "abacaxi"],
    correct: "FAV",
  },
  {
    sample: "JOM",
    context: "SIMILAR",
    options: ["FAV", "abacate", "abacaxi", "CUG"],
    correct: "FAV",
  },
  {
    sample: "JOM",
    context: "SIMILAR",
    options: ["FAV", "abacate", "abacaxi", "CUG", "VEK"],
    correct: "FAV",
  },
  {
    sample: "JOM",
    context: "SIMILAR",
    options: ["REB", "abacate", "maçã"],
    correct: "REB",
  },
  {
    sample: "JOM",
    context: "SIMILAR",
    options: ["REB", "abacate", "maçã", "VEK"],
    correct: "REB",
  },
  {
    sample: "JOM",
    context: "SIMILAR",
    options: ["REB", "abacate", "maçã", "VEK", "LEW"],
    correct: "REB",
  },
  {
    sample: "VEK",
    context: "OPOSTO",
    options: ["FAV", "banana", "abacate"],
    correct: "FAV",
  },
  {
    sample: "VEK",
    context: "OPOSTO",
    options: ["FAV", "banana", "abacate", "ZID"],
    correct: "FAV",
  },
  {
    sample: "VEK",
    context: "OPOSTO",
    options: ["FAV", "banana", "abacate", "ZID", "REB"],
    correct: "FAV",
  },
  {
    sample: "VEK",
    context: "SIMILAR",
    options: ["LEW", "banana", "abacaxi"],
    correct: "LEW",
  },
  {
    sample: "VEK",
    context: "SIMILAR",
    options: ["LEW", "banana", "abacaxi", "ZID"],
    correct: "LEW",
  },
  {
    sample: "VEK",
    context: "SIMILAR",
    options: ["LEW", "banana", "abacaxi", "ZID", "FAV"],
    correct: "LEW",
  },
  {
    sample: "JOM",
    context: "OPOSTO",
    options: ["POG", "abacaxi", "maçã"],
    correct: "POG",
  },
  {
    sample: "JOM",
    context: "OPOSTO",
    options: ["POG", "abacaxi", "maçã", "REB"],
    correct: "POG",
  },
  {
    sample: "JOM",
    context: "OPOSTO",
    options: ["POG", "abacaxi", "maçã", "REB", "FAV"],
    correct: "POG",
  },
  {
    sample: "VEK",
    context: "OPOSTO",
    options: ["JOM", "abacate", "maçã"],
    correct: "JOM",
  },
  {
    sample: "VEK",
    context: "OPOSTO",
    options: ["JOM", "abacate", "maçã", "POG"],
    correct: "JOM",
  },
  {
    sample: "VEK",
    context: "OPOSTO",
    options: ["JOM", "abacate", "maçã", "POG", "LEW"],
    correct: "JOM",
  },
  {
    sample: "VEK",
    context: "SIMILAR",
    options: ["POG", "banana", "abacaxi"],
    correct: "POG",
  },
  {
    sample: "VEK",
    context: "SIMILAR",
    options: ["POG", "banana", "abacaxi", "JOM"],
    correct: "POG",
  },
  {
    sample: "VEK",
    context: "SIMILAR",
    options: ["POG", "banana", "abacaxi", "JOM", "REB"],
    correct: "POG",
  },
  {
    sample: "JOM",
    context: "OPOSTO",
    options: ["LEW", "banana", "abacaxi"],
    correct: "LEW",
  },
  {
    sample: "JOM",
    context: "OPOSTO",
    options: ["LEW", "banana", "abacaxi", "ZID"],
    correct: "LEW",
  },
  {
    sample: "JOM",
    context: "OPOSTO",
    options: ["LEW", "banana", "abacaxi", "ZID", "FAV"],
    correct: "LEW",
  },
];

export const controlListTest1 = [
  {
    sample: "VEK",
    context: "SIMILAR",
    options: ["CUG", "banana", "maçã", "FAV", "REB"],
    correct: "CUG",
  },
  {
    sample: "JOM",
    context: "OPOSTO",
    options: ["VEK", "abacaxi", "maçã", "FAV", "REB"],
    correct: "VEK",
  },
  {
    sample: "JOM",
    context: "SIMILAR",
    options: ["FAV", "abacate", "abacaxi", "CUG", "VEK"],
    correct: "FAV",
  },
  {
    sample: "JOM",
    context: "SIMILAR",
    options: ["REB", "abacate", "maçã", "VEK", "LEW"],
    correct: "REB",
  },
  {
    sample: "VEK",
    context: "OPOSTO",
    options: ["FAV", "banana", "abacate", "ZID", "REB"],
    correct: "FAV",
  },
  {
    sample: "VEK",
    context: "SIMILAR",
    options: ["LEW", "banana", "abacaxi", "ZID", "FAV"],
    correct: "LEW",
  },
  {
    sample: "JOM",
    context: "OPOSTO",
    options: ["POG", "abacaxi", "maçã", "REB", "FAV"],
    correct: "POG",
  },
  {
    sample: "VEK",
    context: "OPOSTO",
    options: ["JOM", "abacate", "maçã", "POG", "LEW"],
    correct: "JOM",
  },
  {
    sample: "VEK",
    context: "SIMILAR",
    options: ["POG", "banana", "abacaxi", "JOM", "REB"],
    correct: "POG",
  },
  {
    sample: "JOM",
    context: "OPOSTO",
    options: ["LEW", "banana", "abacaxi", "ZID", "FAV"],
    correct: "LEW",
  },
];

// List 1: VEK, CUG, POG, LEW
// List 2: JOM, ZID, FAV, REB
// List 3: banana, maçã, abacaxi, abacate

export const controlListTrial2 = [
  {
    sample: "VEK",
    context: "SINÔNIMO",
    options: ["CUG", "banana", "maçã", "FAV", "REB"],
    comparison: "POG",
    correct: "CUG",
  },
  {
    sample: "JOM",
    context: "ANTÔNIMO",
    options: ["VEK", "abacaxi", "maçã", "FAV", "REB"],
    comparison: "CUG",
    correct: "VEK",
  },
  {
    sample: "JOM",
    context: "SINÔNIMO",
    options: ["FAV", "abacate", "abacaxi", "CUG", "VEK"],
    comparison: "ZID",
    correct: "FAV",
  },
  {
    sample: "JOM",
    context: "SINÔNIMO",
    options: ["REB", "abacate", "maçã", "VEK", "LEW"],
    comparison: "FAV",
    correct: "REB",
  },
  {
    sample: "VEK",
    context: "ANTÔNIMO",
    options: ["FAV", "banana", "abacate", "ZID", "REB"],
    comparison: "JOM",
    correct: "FAV",
  },
  {
    sample: "VEK",
    context: "SINÔNIMO",
    options: ["LEW", "banana", "abacaxi", "ZID", "FAV"],
    comparison: "CUG",
    correct: "LEW",
  },
  {
    sample: "JOM",
    context: "ANTÔNIMO",
    options: ["POG", "abacaxi", "maçã", "REB", "FAV"],
    comparison: "VEK",
    correct: "POG",
  },
  {
    sample: "VEK",
    context: "ANTÔNIMO",
    options: ["JOM", "abacate", "maçã", "POG", "LEW"],
    comparison: "REB",
    correct: "JOM",
  },
  {
    sample: "VEK",
    context: "SINÔNIMO",
    options: ["POG", "banana", "abacaxi", "JOM", "REB"],
    comparison: "LEW",
    correct: "POG",
  },
  {
    sample: "JOM",
    context: "ANTÔNIMO",
    options: ["LEW", "banana", "abacaxi", "ZID", "FAV"],
    comparison: "CUG",
    correct: "LEW",
  },
];
