import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";
import css from "./Sidebar.module.css";

export const Sidebar = () => {
  const { userData, userResearch } = React.useContext(UserContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  if (userData && userResearch) {
    return (
      <div className={css.sidebar}>
        <div className={css.sidebarWrapper}>
          <div className={css.sidebarMenu}>
            <button className={css.userButton} onClick={() => navigate("/")} />
            <h3 className={css.sidebarTitle}>{userData.displayName}</h3>
            <h4 className={css.sidebarTitle}>Dia {userResearch.trialDay}</h4>
            <ul className={css.sidebarList}>
              {userResearch.trialDay === 3 && userResearch.phase === 7 ? (
                <Link to="/cloud1" className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>cloud</i>
                    Nuvem 1
                  </li>
                </Link>
              ) : (
                <Link to="/cloud1" className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.disable}`}>
                    <i
                      className={`material-icons ${css.sidebarIcon} ${css.disable}`}
                    >
                      cloud
                    </i>
                    Nuvem 1
                  </li>
                </Link>
              )}
              {userResearch.trialDay === 3 && userResearch.phase === 7 ? (
                <Link to="/cloud2" className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>cloud</i>
                    Nuvem 2
                  </li>
                </Link>
              ) : (
                <Link to="/cloud2" className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.disable}`}>
                    <i
                      className={`material-icons ${css.sidebarIcon} ${css.disable}`}
                    >
                      cloud
                    </i>
                    Nuvem 2
                  </li>
                </Link>
              )}
              {userResearch.trialDay === 3 && userResearch.phase === 7 ? (
                <Link to="/cloud3" className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>cloud</i>
                    Nuvem 3
                  </li>
                </Link>
              ) : (
                <Link to="/cloud3" className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.disable}`}>
                    <i
                      className={`material-icons ${css.sidebarIcon} ${css.disable}`}
                    >
                      cloud
                    </i>
                    Nuvem 3
                  </li>
                </Link>
              )}
              {userResearch.trialDay === 3 && userResearch.phase === 7 ? (
                <Link to="/analysis" className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      leaderboard
                    </i>
                    Resultados
                  </li>
                </Link>
              ) : (
                <Link to="/analysis" className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.disable}`}>
                    <i
                      className={`material-icons ${css.sidebarIcon} ${css.disable}`}
                    >
                      leaderboard
                    </i>
                    Resultados
                  </li>
                </Link>
              )}

              {userData.staff === true && (
                <Link to="/wordanalysis" className={css.link}>
                  <li className={css.sidebarListItem}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      pie_chart
                    </i>
                    Análise
                  </li>
                </Link>
              )}
            </ul>
          </div>

          <div
            className={`copyright ${
              userData.staff ? css.sidebarMenu : css.copyright
            } `}
          >
            mairini.com © 2024
          </div>
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
