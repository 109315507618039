import React from "react";
import {
  updateProfile,
  updateEmail,
  sendPasswordResetEmail,
  sendEmailVerification,
} from "firebase/auth";

import { UserContext } from "../../Context/UserContext";
import { Head } from "../../Utils/Head";
import { Input } from "../Forms/Input";
import { Button } from "../Forms/Button";
import { useForm } from "../../Hooks/useForm";
import { auth } from "../../firebaseConfig";
import { updateUserFirebase } from "../../Utils/firebaseUser";

export const Account = () => {
  const { user } = React.useContext(UserContext);
  const email = useForm("email");
  const name = useForm();
  const [displayName, setDisplayName] = React.useState("");
  const [userEmail, setUserEmail] = React.useState("");
  const [emailSended, setEmailSended] = React.useState(false);

  React.useEffect(() => {
    setDisplayName(user.displayName);
    setUserEmail(user.email);
  }, [user]);

  function handleSubmitProfile(e) {
    e.preventDefault();
    let updatedName = name.value;
    updateUserFirebase(user, updatedName, user.email);
    updateProfile(auth.currentUser, {
      displayName: updatedName,
    });
    setDisplayName(name.value);
  }

  function handleSubmitEmail(event) {
    event.preventDefault();
    var nameArray = displayName.split(" ");
    var userName = nameArray[0];
    updateUserFirebase(user, userName, email.value);
    updateEmail(auth.currentUser, email.value);
    updateProfile(auth.currentUser, { emailVerified: false });
    setUserEmail(email.value);
    sendEmailVerification(auth.currentUser).then(() => {
      console.log("Email verification sent!");
      // ...
    });
  }

  return (
    <div>
      <Head title="Profile" />
      <h1 className="title">Perfil</h1>
      {user && (
        <>
          <p>Nome: {displayName}</p>
          <p>Email: {userEmail}</p>

          <h2>Atualizar Nome</h2>
          <form action="" onSubmit={handleSubmitProfile}>
            <Input
              label="Nome completo"
              type="name"
              name="name"
              required={true}
              {...name}
            />
            <Button>Enviar</Button>
          </form>
          <form action="" onSubmit={handleSubmitEmail}>
            <h2>Atualizar Email</h2>
            <Input label="Email" type="email" name="email" {...email} />
            <Button>Enviar</Button>
          </form>
          <h2>Alterar Senha</h2>
          <button
            className="defaultButton"
            onClick={() => {
              setEmailSended(true);
              sendPasswordResetEmail(auth, user.email).then(() => {
                console.log("Password reset email sent!");
              });
            }}
          >
            Aperte aqui para enviar um email para alterar a senha
          </button>
          {emailSended && <div>email enviado</div>}
        </>
      )}
    </div>
  );
};
