import { Link } from "react-router-dom";

import css from "./CardPro.module.css";

export const CardPro = (props) => {
  return (
    <div className={css.container} onClick={props.onClick}>
      <div>
        <div className={css.id}>
          <div>
            <i className={`material-icons ${css.cardIcon}`}>account_circle</i>
          </div>
          <div className={css.user}>
            <p>
              <Link className={css.userName} to={`/pro/${props.userId}`}>
                {props.userName}
              </Link>
            </p>
            <span>Médico</span>
          </div>
        </div>
        <div>
          <p className={css.option}>Clinica</p>
          <p className={css.option}>Teleatendimento</p>
        </div>
      </div>
      <div>
        <p>Horarios</p>
        <p>8:00</p>
        <p>9:00</p>
        <p>10:00</p>
      </div>
    </div>
  );
};
