import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import css from "../Research/Research.module.css";
import SAMrect from "../../assets/SAMrect.png";
import SAMrectSelected from "../../assets/SAMrectSelected.png";
import { ANEWBr } from "../../Utils/ANEWBr";
import { Button } from "../Forms/Button";

export const TestWords = () => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(-1);
  const [complete, setComplete] = useState(0);
  const [ITI, setITI] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(6);
  const [start, setStart] = useState(false);
  const [finish, setFinish] = useState(false);
  const [finishList, setFinishList] = useState([]);
  const [reactionTime, setReactionTime] = useState(Date.now());
  const [reactionTimeList, setReactionTimeList] = useState(
    new Array(40).fill(0)
  );
  const arrVal = new Array(40);
  for (let i = 0; i < arrVal.length; i++) {
    arrVal[i] = new Array(9).fill(false);
  }
  const [SAMval, setSAMval] = useState(arrVal);
  const arrAle = new Array(40);
  for (let i = 0; i < arrAle.length; i++) {
    arrAle[i] = new Array(9).fill(false);
  }
  const [SAMale, setSAMale] = useState(arrAle);

  useEffect(() => {
    if (start) {
      setTimeout(function () {
        if (secondsRemaining > 0) {
          setSecondsRemaining(secondsRemaining - 1);
        }
      }, 1000);
    }
    if (ITI) {
      setTimeout(function () {
        setITI(false);
      }, 1000);
    }
    if (secondsRemaining === 0) {
      setStart(false);
    }
  }, [start, secondsRemaining, ITI]);

  function handleCheck(type, col) {
    let arrayReactionTime = [...reactionTimeList];
    arrayReactionTime[index] = Date.now() - reactionTime;
    setReactionTimeList(arrayReactionTime);

    let sum = complete + 1;
    setComplete(sum);
    let arrayVal = [...SAMval];
    let arrayAle = [...SAMale];
    if (type === 0) {
      if (arrayVal[index].includes(true)) {
        if (arrayVal[index][col] === true) {
          return;
        } else {
          let position = arrayVal[index].indexOf(true);
          arrayVal[index][position] = false;
          arrayVal[index][col] = !arrayVal[index][col];
          setSAMval(arrayVal);
        }
      } else {
        arrayVal[index][col] = !arrayVal[index][col];
        setSAMval(arrayVal);
      }
    } else {
      if (arrayAle[index].includes(true)) {
        if (arrayAle[index][col] === true) {
          arrayAle[index][col] = !arrayAle[index][col];
          setSAMale(arrayAle);
        } else {
          let position = arrayAle[index].indexOf(true);
          arrayAle[index][position] = false;
          arrayAle[index][col] = !arrayAle[index][col];
          setSAMale(arrayAle);
        }
      } else {
        arrayAle[index][col] = !arrayAle[index][col];
        setSAMale(arrayAle);
      }
    }
  }

  function nextWord() {
    if (countSAMChecked() === 2) {
      !finish && submit();
      !finish && setFinish(true);
    } else {
      index + 1 && setITI(true);
      setSecondsRemaining(6);
      setReactionTime(Date.now());
      index + 1 === 5 ? setIndex(0) : setIndex(index + 1);
      setStart(true);
    }
  }

  function submit() {
    let SAMvalReduced = SAMval.map((row) => row.indexOf(true) + 1);
    let ListSAM = [];
    for (let index = 0; index < SAMvalReduced.length; index++) {
      let obj = {
        word: ANEWBr[index],
        valence: SAMvalReduced[index],
        reactionTime: reactionTimeList[index],
      };
      ListSAM.push(obj);
    }
    let ListSAMfiltered = ListSAM.filter((a) => a.valence !== 0);
    let ListSAMsorted = ListSAMfiltered.sort((a, b) => a.valence - b.valence);
    setFinishList(ListSAMsorted);
    // console.log(ListSAMsorted);

    // setTimeout(() => {
    //   navigate("/");
    // }, 1500);
  }

  function countSAMChecked() {
    let arr = [...SAMval];
    let count = 0;
    arr.forEach((row) => row.forEach((item) => item !== false && count++));
    return count;
  }

  if (ITI) {
    return (
      <div className={css.container}>
        <div className={css.iti}></div>
      </div>
    );
  } else if (index < 0) {
    return (
      <div className={css.container}>
        <h1>Avaliar palavras</h1>
        <p>
          A seguir serão apresentadas 40 palavras. Nesta etapa você irá avaliar
          a valência de cada palavra dentro de uma escala de 1-9 pontos.
        </p>
        <p>
          Para avaliação da valencia, marque o setor da escala que corresponde
          ao quão agradável (mais próximo da palavra "positivo") ou desagradável
          (mais próximo da palavra "negativo").
        </p>
        <p>
          Você terá 5 segundos para avaliar cada palavra. Um contador na parte
          superior esquerda da tela indicará o tempo. Após terminado o tempo,
          outra palavra será apresentada.
        </p>
        <Button onClick={() => nextWord()}>Iniciar</Button>
      </div>
    );
  } else {
    return (
      <div className={css.container}>
        <div className={css.samScale}>
          {secondsRemaining === 0 && <>{nextWord()}</>}
          {!finish && (
            <>
              <div>{/* <span>00:0{secondsRemaining}</span> */}</div>
              <h1>{ANEWBr[index]}</h1>
              <form className={css.samItens}>
                <strong>POSITIVO</strong>
                <label className={css.confirmation}>
                  {SAMval[index][0] ? (
                    <img src={SAMrectSelected} alt="" />
                  ) : (
                    <img src={SAMrect} alt="" />
                  )}
                  <input
                    type="checkbox"
                    checked={SAMval[index][0]}
                    onChange={() => {
                      handleCheck(0, 0);
                    }}
                  />
                  {/* <span className={css.checkmark}></span> */}
                </label>
                <label className={css.confirmation}>
                  {SAMval[index][1] ? (
                    <img src={SAMrectSelected} alt="" />
                  ) : (
                    <img src={SAMrect} alt="" />
                  )}
                  <input
                    type="checkbox"
                    checked={SAMval[index][1]}
                    onChange={() => {
                      handleCheck(0, 1);
                    }}
                  />
                  {/* <span className={css.checkmark}></span> */}
                </label>
                <label className={css.confirmation}>
                  {SAMval[index][2] ? (
                    <img src={SAMrectSelected} alt="" />
                  ) : (
                    <img src={SAMrect} alt="" />
                  )}
                  <input
                    type="checkbox"
                    checked={SAMval[index][2]}
                    onChange={() => {
                      handleCheck(0, 2);
                    }}
                  />
                  {/* <span className={css.checkmark}></span> */}
                </label>
                <label className={css.confirmation}>
                  {SAMval[index][3] ? (
                    <img src={SAMrectSelected} alt="" />
                  ) : (
                    <img src={SAMrect} alt="" />
                  )}
                  <input
                    type="checkbox"
                    checked={SAMval[index][3]}
                    onChange={() => {
                      handleCheck(0, 3);
                    }}
                  />
                  {/* <span className={css.checkmark}></span> */}
                </label>
                <label className={css.confirmation}>
                  {SAMval[index][4] ? (
                    <img src={SAMrectSelected} alt="" />
                  ) : (
                    <img src={SAMrect} alt="" />
                  )}
                  <input
                    type="checkbox"
                    checked={SAMval[index][4]}
                    onChange={() => {
                      handleCheck(0, 4);
                    }}
                  />
                  {/* <span className={css.checkmark}></span> */}
                </label>
                <label className={css.confirmation}>
                  {SAMval[index][5] ? (
                    <img src={SAMrectSelected} alt="" />
                  ) : (
                    <img src={SAMrect} alt="" />
                  )}
                  <input
                    type="checkbox"
                    checked={SAMval[index][5]}
                    onChange={() => {
                      handleCheck(0, 5);
                    }}
                  />
                  {/* <span className={css.checkmark}></span> */}
                </label>
                <label className={css.confirmation}>
                  {SAMval[index][6] ? (
                    <img src={SAMrectSelected} alt="" />
                  ) : (
                    <img src={SAMrect} alt="" />
                  )}
                  <input
                    type="checkbox"
                    checked={SAMval[index][6]}
                    onChange={() => {
                      handleCheck(0, 6);
                    }}
                  />
                  {/* <span className={css.checkmark}></span> */}
                </label>
                <label className={css.confirmation}>
                  {SAMval[index][7] ? (
                    <img src={SAMrectSelected} alt="" />
                  ) : (
                    <img src={SAMrect} alt="" />
                  )}
                  <input
                    type="checkbox"
                    checked={SAMval[index][7]}
                    onChange={() => {
                      handleCheck(0, 7);
                    }}
                  />
                  {/* <span className={css.checkmark}></span> */}
                </label>
                <label className={css.confirmation}>
                  {SAMval[index][8] ? (
                    <img src={SAMrectSelected} alt="" />
                  ) : (
                    <img src={SAMrect} alt="" />
                  )}
                  <input
                    type="checkbox"
                    checked={SAMval[index][8]}
                    onChange={() => {
                      handleCheck(0, 8);
                    }}
                  />
                  {/* <span className={css.checkmark}></span> */}
                </label>
                <strong>NEGATIVO</strong>
              </form>

              <div className={css.samControl}>
                {/* <p>{countSAMChecked() + "/" + 40}</p> */}
              </div>
            </>
          )}
          {finish && (
            <div>
              <h1>Resultados</h1>
              {finishList.map((item, i) => (
                <div key={i}>
                  <p>
                    <strong>{item.word}</strong>
                  </p>
                  <p>Valência negativa: {item.valence}</p>
                  <p>Tempo de reação: {item.reactionTime} ms</p>
                  <hr />
                </div>
              ))}
              <Button onClick={() => navigate("/")}>Retornar</Button>
            </div>
          )}
        </div>
      </div>
    );
  }
};
