import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import css from "./Research.module.css";
import { Button } from "../Forms/Button";
import { DASS } from "../../Utils/DASS";
import { createResearchFirebase } from "../../Utils/firebaseResearch";
import { UserContext } from "../../Context/UserContext";

export const Inventories = () => {
  const { userResearch, setUserResearch } = useContext(UserContext);
  const DASSarr = new Array(21);
  for (let i = 0; i < DASSarr.length; i++) {
    DASSarr[i] = new Array(4).fill(false);
  }
  const [DASSCheck, setDASSCheck] = useState(DASSarr);
  const [completeDASS, setCompleteDASS] = useState(0);
  const scoreArr = [0, 1, 2, 3];
  const PiCDBrarr = new Array(60);
  for (let i = 0; i < PiCDBrarr.length; i++) {
    PiCDBrarr[i] = new Array(5).fill(false);
  }
  const navigate = useNavigate();

  function validade() {
    let count = 0;
    DASSCheck.forEach((row) => {
      if (row.includes(true) >= 1) {
        count++;
      }
    });
    if (count >= 21) {
      return true;
    } else {
      return false;
    }
  }

  function handleCheckDASS(row, col) {
    let sum = completeDASS + 1;
    setCompleteDASS(sum);
    let array = [...DASSCheck];
    if (array[row].includes(true)) {
      if (array[row][col]) {
        array[row][col] = false;
        setDASSCheck(array);
      } else {
        let position = array[row].indexOf(true);
        array[row][position] = false;
        array[row][col] = !array[row][col];
        setDASSCheck(array);
      }
    } else {
      array[row][col] = !array[row][col];
      setDASSCheck(array);
    }
  }

  function submmit() {
    let DASSreduced = DASSCheck.map((row) =>
      row.indexOf(true) >= 0 ? row.indexOf(true) : 0
    );
    const DepArr = [3, 5, 10, 13, 16, 17, 21];
    const AnxArr = [2, 4, 7, 9, 15, 19, 20];
    const StrArr = [1, 6, 8, 11, 12, 14, 18];
    let scoreDep = 0;
    let scoreAnx = 0;
    let scoreStr = 0;

    for (let index = 0; index < DepArr.length; index++) {
      DASSreduced[DepArr[index] - 1] >= 0
        ? (scoreDep = scoreDep + DASSreduced[DepArr[index] - 1])
        : console.log(scoreDep);
    }
    for (let index = 0; index < DepArr.length; index++) {
      DASSreduced[AnxArr[index] - 1] >= 0
        ? (scoreAnx = scoreAnx + DASSreduced[AnxArr[index] - 1])
        : console.log(scoreAnx);
    }
    for (let index = 0; index < DepArr.length; index++) {
      DASSreduced[StrArr[index] - 1] >= 0
        ? (scoreStr = scoreStr + DASSreduced[StrArr[index] - 1])
        : console.log(scoreStr);
    }

    let resUser = userResearch;
    resUser.phase = 2;
    if (resUser.trialDay < 2) {
      resUser.DASS = DASSreduced;
      resUser.DASSdep = scoreDep;
      resUser.DASSanx = scoreAnx;
      resUser.DASSstr = scoreStr;
    } else if (resUser.trialDay === 2) {
      resUser.DASSDay2 = DASSreduced;
      resUser.DASSdepDay2 = scoreDep;
      resUser.DASSanxDay2 = scoreAnx;
      resUser.DASSstrDay2 = scoreStr;
    } else if (resUser.trialDay === 3) {
      resUser.DASSDay3 = DASSreduced;
      resUser.DASSdepDay3 = scoreDep;
      resUser.DASSanxDay3 = scoreAnx;
      resUser.DASSstrDay3 = scoreStr;
    }
    createResearchFirebase(resUser);
    setUserResearch(resUser);
    navigate("/");
  }

  return (
    <section className={css.container}>
      <h1 className="scroller">Inventário</h1>
      <div className={css.instructions}>
        <p>
          Por favor, leia cuidadosamente cada uma das afirmações abaixo e marque
          o número apropriado 0, 1, 2 ou 3 que indique o quanto ela se aplicou a
          você durante a última semana, conforme a indicação a seguir:
        </p>
        <p>0 Não se aplicou de maneira alguma</p>
        <p>1 Aplicou-se em algum grau, ou por pouco tempo</p>
        <p>
          2 Aplicou-se em um grau considerável, ou por uma boa parte do tempo
        </p>
        <p>3 Aplicou-se muito, ou na maioria do tempo</p>
      </div>
      <div>
        <form>
          {DASS.map((questions, row) => (
            <div className={css.questions} key={row}>
              <strong>{questions}.</strong>

              {scoreArr.map((q, col) => (
                <label className={css.confirmation} key={col}>
                  {q === 0
                    ? "0 - Não se aplicou de maneira alguma"
                    : q === 1
                    ? "1 - Aplicou-se em algum grau, ou por pouco tempo"
                    : q === 2
                    ? "2 - Aplicou-se em um grau considerável, ou por uma boa parte do tempo"
                    : q === 3
                    ? "3 - Aplicou-se muito, ou na maioria do tempo"
                    : ""}
                  <input
                    type="checkbox"
                    checked={DASSCheck[row][col]}
                    onChange={() => {
                      handleCheckDASS(row, col);
                    }}
                  />
                  <span className={css.checkmark}></span>
                </label>
              ))}
            </div>
          ))}
        </form>
      </div>
      <Button disabled={validade() ? false : true} onClick={() => submmit()}>
        {/* <Button disabled={false} onClick={() => submmit()}> */}
        Enviar
      </Button>
    </section>
  );
};
