import React, { useRef, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import css from "./Trial.module.css";
import { Head } from "../../Utils/Head";
import {
  createResearchFirebase,
  getResearchFirebase,
} from "../../Utils/firebaseResearch";
import { ANEWBrSinAnt } from "../../Utils/ANEWBr";
import { UserContext } from "../../Context/UserContext";
import instructionImg from "../../assets/instruction1.gif";
import { controlListTest1 } from "../../Utils/ANEWBr";

export const Test2 = () => {
  const { user, userResearch, setUserResearch, sessionTime } =
    useContext(UserContext);
  const [score, setScore] = useState(0);
  const [listTrials, setListTrials] = useState([]);
  const [choices, setChoices] = useState([]);
  const [showSample, setShowSample] = useState(false);
  const [showContext, setShowContext] = useState(false);
  const [showBlank, setShowBlank] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [ITI, setITI] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [choice, setChoice] = useState(false);
  const [trialEnd, setTrialEnd] = useState(false);
  const [choiceOver, setChoiceOver] = useState(false);
  const [choiceEnter, setChoiceEnter] = useState(false);
  const [consequence, setConsequence] = useState(false);
  const dragItem = useRef();
  const dragNode = useRef();
  const dropItem = useRef();
  const navigate = useNavigate();
  const [startPhase, setStartPhase] = useState(false);
  const [endPhase, setEndPhase] = useState(false);
  const [index, setIndex] = useState(0);
  const trials = [];
  const listT = [];
  let listModelP = [];
  let listModelN = [];
  let listComparison = [];
  let listComparisonP = [];
  let listComparisonN = [];
  const listVal = [[], [], [], [], [], [], [], [], []];

  useEffect(() => {
    function shuffleArray(array) {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    }

    userResearch.group &&
      getResearchFirebase(user).then(function (result) {
        let wordArr = [];
        if (result) {
          result.trialDay < 2
            ? (wordArr = result.WordVal)
            : result.trialDay === 2
            ? (wordArr = result.WordValDay2)
            : (wordArr = result.WordValDay3);
        }

        if (
          result &&
          listT
            .map((obj) => obj.word)
            .indexOf(wordArr[wordArr.length - 1].word) <= 0
        ) {
          for (let index = 0; index < wordArr.length; index++) {
            wordArr[index].valence === 1 && listVal[0].push(wordArr[index]);
            wordArr[index].valence === 2 && listVal[1].push(wordArr[index]);
            wordArr[index].valence === 3 && listVal[2].push(wordArr[index]);
            wordArr[index].valence === 4 && listVal[3].push(wordArr[index]);
            wordArr[index].valence === 5 && listVal[4].push(wordArr[index]);
            wordArr[index].valence === 6 && listVal[5].push(wordArr[index]);
            wordArr[index].valence === 7 && listVal[6].push(wordArr[index]);
            wordArr[index].valence === 8 && listVal[7].push(wordArr[index]);
            wordArr[index].valence === 9 && listVal[8].push(wordArr[index]);
            listT.push(wordArr[index]);
          }

          listT.sort((a, b) => a.valence - b.valence);
          listModelP = listT.slice(0, 5);
          listModelN = listT.slice(listT.length - 5, listT.length);

          for (let index = 0; index < 9; index++) {
            if (listModelP[4].valence + index < listModelN[0].valence - 1) {
              listComparison = listComparison.concat(
                listVal[listModelP[4].valence + index]
              );
            }
          }

          // for (let index = 0; index < listComparison.length; index++) {
          //   listComparison[index].valence < 5 &&
          //     listComparisonP.push(listComparison[index].word);
          //   listComparison[index].valence >= 5 &&
          //     listComparisonN.push(listComparison[index].word);
          // }

          listComparisonP = listComparison.slice(
            0,
            Math.round(listComparison.length / 2)
          );
          listComparisonN = listComparison.slice(
            Math.round(listComparison.length / 2),
            listComparison.length
          );

          // console.log(listModelP);
          // console.log(listModelN);
          // console.log(listComparison);
          // console.log(listComparisonN);
          // console.log(listComparisonP);

          const createTrialPositive = (sample, context) => {
            let obj = {};
            let options = [];
            obj.sample = sample;
            obj.context = context;

            obj.correct =
              ANEWBrSinAnt[
                ANEWBrSinAnt.map((o) => o.sample).indexOf(listModelP[0].word)
              ].sin;
            listModelP.splice(0, 1);

            options.push(obj.correct);

            function getRandomIndex(max) {
              max = Math.floor(max);
              return Math.floor(Math.random() * (max + 1));
            }

            while (options.length < 3) {
              const randomIndexNeg = getRandomIndex(listComparisonN.length - 1);
              if (options.indexOf(listComparisonN[randomIndexNeg].word) <= 0) {
                options.push(listComparisonN[randomIndexNeg].word);
              }
            }

            while (options.length < 5) {
              const randomIndexPos = getRandomIndex(listComparisonP.length - 1);
              if (options.indexOf(listComparisonP[randomIndexPos].word) <= 0) {
                options.push(listComparisonP[randomIndexPos].word);
              }
            }

            obj.options = shuffleArray(options);
            return obj;
          };

          const createTrialNegative = (sample, context) => {
            let obj = {};
            let options = [];
            obj.sample = sample;
            obj.context = context;

            obj.correct =
              ANEWBrSinAnt[
                ANEWBrSinAnt.map((o) => o.sample).indexOf(listModelN[0].word)
              ].sin;
            listModelN.splice(0, 1);

            options.push(obj.correct);

            function getRandomIndex(max) {
              max = Math.floor(max);
              return Math.floor(Math.random() * (max + 1));
            }

            while (options.length < 3) {
              const randomIndexPos = getRandomIndex(listComparisonP.length - 1);
              if (options.indexOf(listComparisonP[randomIndexPos].word) <= 0) {
                options.push(listComparisonP[randomIndexPos].word);
              }
            }

            while (options.length < 5) {
              const randomIndexNeg = getRandomIndex(listComparisonN.length - 1);
              if (options.indexOf(listComparisonN[randomIndexNeg].word) <= 0) {
                options.push(listComparisonN[randomIndexNeg].word);
              }
            }

            /* Randomize array in-place using Durstenfeld shuffle algorithm */
            function shuffleArray(array) {
              for (var i = array.length - 1; i > 0; i--) {
                var j = Math.floor(Math.random() * (i + 1));
                var temp = array[i];
                array[i] = array[j];
                array[j] = temp;
              }
              return array;
            }

            obj.options = shuffleArray(options);
            return obj;
          };

          trials.push(createTrialPositive("Eu me sinto", "SIMILAR", 5));
          trials.push(createTrialNegative("Nunca estou", "SIMILAR", 5));
          trials.push(createTrialNegative("Sempre fico", "OPOSTO", 5));

          setListTrials(trials);
          console.log(trials);
        }
      });

    controlListTest1.map((o) => (o.options = shuffleArray(o.options)));

    !userResearch.group && setListTrials(controlListTest1);

    console.log(listTrials);
  }, [user]);

  useEffect(() => {
    // if (startPhase) {
    //   setTimeout(function () {
    //     setShowContext(true);
    //   }, 1000);
    //   setTimeout(function () {
    //     setShowBlank(true);
    //   }, 2000);
    //   setTimeout(function () {
    //     setShowOptions(true);
    //   }, 3000);
    // }
    if (ITI) {
      setTimeout(function () {
        setITI(false);
      }, 1000);
    }
  }, [startPhase, ITI]);

  const handleDragStart = (e, position, word) => {
    console.log("draging...", position);
    dragItem.current = position;
    dragNode.current = e.target;
    dragNode.current.addEventListener("dragend", handleDragEnd);
    setChoice(word);
    setTimeout(() => {
      setDragging(word);
    }, 0);
  };

  const handleDragEnter = (e, params) => {
    console.log("Entering drag...");
    dropItem.current = e.target;
    dropItem.current.addEventListener("dragover", handleDragOver);
    dropItem.current.addEventListener("dragleave", handleDragLeave);
    dropItem.current.addEventListener("drop", handleDrop);

    // const currentItem = dragItem.current;
    // if (e.target !== dragNode.current) {
    //   console.log("TARGET IS NOT THE SAME");
    //   setChoices((oldChoices) => {
    //     let newChoices = JSON.parse(JSON.stringify(oldChoices));
    //     newChoices[params.grpI].items.splice(
    //       params.item,
    //       0,
    //       newChoices[currentItem.grpI].items.splice(currentItem.itemI, 1)[0]
    //     );
    //     dragItem.current = params;
    //     return newChoices;
    //   });
    // }
  };

  const handleDragOver = (e) => {
    console.log("Over drag...");
    const oldL = [...listTrials[index].options];
    setChoices(oldL);
    e.stopPropagation();
    e.preventDefault();
    setChoiceOver(true);
  };

  const handleDragLeave = () => {
    console.log("Leave drag...");
    setChoiceOver(false);
  };

  const handleDrop = () => {
    console.log("Dropped");
    listTrials[index].options[dragItem.current] = "";
    setChoiceEnter(dragging);
    setTrialEnd(true);
  };

  const handleDragEnd = () => {
    console.log("Ending drag...", dragItem.current);
    setDragging(false);
    dragNode.current.removeEventListener("dragend", handleDragEnd);
    dropItem.current.removeEventListener("dragover", handleDragOver);
    dropItem.current.removeEventListener("drop", handleDrop);
    dragItem.current = null;
    dragNode.current = null;
  };

  const getStyles = (itemStyle) => {
    const currentItem = dragItem.current;
    if (currentItem === itemStyle) {
      return css.cardDraging;
    }
    return css.card;
  };

  const getDropzone = (i) => {
    if (choiceEnter && dragItem.current === null) {
      return (
        <div
          key={i}
          className={dragging ? getStyles(i) : css.card}
          draggable={trialEnd ? false : true}
          onDragStart={(e) => handleDragStart(e, i)}
        >
          {choiceEnter}
        </div>
      );
    } else {
      if (choiceOver) {
        return (
          <div
            key={i}
            className={css.dropzoneActive}
            onDrop={(e) => handleDrop(e, i)}
          ></div>
        );
      } else {
        return (
          <div
            key={i}
            className={css.dropzone}
            onDragEnter={(e) => handleDragEnter(e, i)}
            onDrop={(e) => handleDrop(e, i)}
          ></div>
        );
      }
    }
  };

  const restart = () => {
    listTrials[index].options = choices;
    setDragging(false);
    setChoiceEnter(false);
    setTrialEnd(false);
    setChoiceOver(false);
  };

  const checkCorrect = () => {
    let correct = false;
    let scoreTemp = 1;

    if (listTrials[index].correct === choice) {
      scoreTemp = scoreTemp + score;
      correct = "correct";
      listTrials[index].score = 1;
    } else {
      scoreTemp = score;
      correct = "incorrect";
      listTrials[index].score = 0;
    }

    setTimeout(() => {
      phaseEnded() ? finish(scoreTemp) : next();
    }, 1500);
    return (
      setScore(scoreTemp), setConsequence(correct), setListTrials(listTrials)
    );
  };

  function phaseEnded() {
    if (index + 1 === listTrials.length) {
      return true;
    } else {
      return false;
    }
  }

  function next() {
    listTrials[index].options = choices;
    setConsequence(false);
    setIndex(index + 1);
    setDragging(false);
    setChoiceEnter(false);
    setTrialEnd(false);
    setChoiceOver(false);
    setShowSample(false);
    setShowContext(false);
    setShowBlank(false);
    setShowOptions(false);
    setChoices(listTrials[index + 1].options);
    // setTimeout(function () {
    //   setShowContext(true);
    // }, 1000);
    // setTimeout(function () {
    //   setShowBlank(true);
    // }, 2000);
    // setTimeout(function () {
    //   setShowOptions(true);
    // }, 3000);
    index + 1 && setITI(true);
  }

  const finish = (scoreTemp) => {
    listTrials[index].options = choices;
    let resUser = userResearch;
    const Pscore = (scoreTemp * 100) / listTrials.length;
    if (resUser.trialDay < 2) {
      resUser.Test2score = Pscore;
      resUser.phase = 7;
      resUser.Test2 = listTrials;
      resUser.sessionTime = sessionTime;
    } else if (resUser.trialDay === 2) {
      resUser.Test2scoreDay2 = Pscore;
      resUser.phase = 7;
      resUser.Test2Day2 = listTrials;
      resUser.sessionTimeDay2 = sessionTime;
    } else if (resUser.trialDay === 3) {
      resUser.Test2scoreDay3 = Pscore;
      resUser.phase = 7;
      resUser.Test2Day3 = listTrials;
      resUser.sessionTimeDay3 = sessionTime;
    }

    createResearchFirebase(resUser);
    setUserResearch(resUser);
    // console.log(resUser);

    setConsequence(false);
    navigate("/");
  };

  if (ITI) {
    return (
      <div className={css.container}>
        <section className={css.sampleGrid}>
          <div className={css.containerText}>
            <div className={css.iti}></div>
          </div>
        </section>
      </div>
    );
  } else if (!startPhase) {
    return (
      <div className={css.container}>
        <section className={css.sampleGrid}>
          <div className={css.containerText}>
            <h1>Instruções</h1>
            <p>
              A seguir, na parte superior da tela, você terá que clicar da
              esquerda para a direita nos quadrados apresentados. Após o clique,
              algumas palavras aparecerão na parte superior e inferior da tela,
              conforme o vídeo abaixo.
            </p>
            <img src={instructionImg} alt="" />
            <p>
              Nesta etapa, você irá escolher a palavra que melhor deve se
              encaixa no campo em branco. Para fazer a escolha você precisa
              clicar na palavra e arrastar com o mouse até o campo em branco. Em
              seguida, poderá escolher entre recomeçar ou terminar a escolha por
              meio de duas opções que aparecerão no campo inferior da tela.
            </p>
            <p>
              Após terminar a tentativa, não será informado se a escolha foi a
              correta ou incorreta. Em seguida, uma nova tentativa será
              apresentada, até que essa etapa termine.
            </p>
            <p>Clique em começar para iniciar a tentativa.</p>
            <div className={css.buttonText} onClick={() => setStartPhase(true)}>
              Começar
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <div className={css.container}>
        <Head title="Trial" />
        {!endPhase ? (
          <>
            <section className={css.sampleGrid}>
              <div className={css.sample}>
                {showSample ? (
                  <div className={css.card}>{listTrials[index].sample}</div>
                ) : (
                  <div
                    className={css.blankField}
                    onClick={() => setShowSample(true)}
                  ></div>
                )}
                {showContext ? (
                  <div>
                    <h2>{listTrials[index].context}</h2>
                  </div>
                ) : (
                  <div
                    className={css.blankField}
                    onClick={() =>
                      showSample ? setShowContext(true) : setShowContext(false)
                    }
                  ></div>
                )}
                {showBlank ? (
                  <div className={css.blank}>{getDropzone("")}</div>
                ) : (
                  <div
                    className={css.blankField}
                    onClick={
                      showSample && showContext
                        ? () => {
                            setShowBlank(true);
                            setShowOptions(true);
                          }
                        : () => setShowBlank(false)
                    }
                  ></div>
                )}
              </div>
            </section>
            <section className={css.optionsGrid}>
              {showOptions ? (
                <div id="div1" className={css.options}>
                  {listTrials[index].options.map((word, i) => (
                    <div
                      key={i}
                      className={
                        word
                          ? dragging
                            ? getStyles(i)
                            : css.card
                          : css.dropzone
                      }
                      draggable={trialEnd && choiceEnter ? false : true}
                      onDragStart={(e) => handleDragStart(e, i, word)}
                    >
                      {word}
                    </div>
                  ))}
                </div>
              ) : (
                <div></div>
              )}
            </section>
            <section className={css.finishGrid}>
              {trialEnd && choiceEnter && !consequence && (
                <div className={css.finish}>
                  <div className={css.cardButton} onClick={() => restart()}>
                    Recomeçar
                  </div>
                  <div
                    className={css.cardButton}
                    onClick={() => checkCorrect()}
                  >
                    Terminar
                  </div>
                </div>
              )}
            </section>
            {/* <section className={css.finishGrid}>
              {trialEnd && choiceEnter && consequence && (
                <>
                  {consequence === "correct" ? (
                    <div className={css.consequence}>
                      <div className={css.cardCorrect}>Correto</div>
                    </div>
                  ) : (
                    <div className={css.consequence}>
                      <div className={css.cardIncorrect}>Incorreto</div>
                    </div>
                  )}
                </>
              )}
            </section> */}
          </>
        ) : (
          <div>A tentativa terminou.</div>
        )}
      </div>
    );
  }
};
