import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import "./App.css";
import "./theme.css";
import { UserStorage } from "./Context/UserContext";
import { Account } from "./Components/Account/Account";
import { RequireAuth } from "./Utils/RequireAuth";
import { Header } from "./Components/Layout/Header";
import { LeftMenu } from "./Components/Layout/LeftMenu";
import { Content } from "./Components/Layout/Content";
import { Login } from "./Components/Login/Login";
import { LoginCreate } from "./Components/Login/LoginCreate";
import { Submission } from "./Components/Login/Submission";
import { Research } from "./Components/Research/Research";
import { Trial1 } from "./Components/Research/Trial1";
import { Trial2 } from "./Components/Research/Trial2";
import { Test1 } from "./Components/Research/Test1";
import { Test2 } from "./Components/Research/Test2";
import { UserPage } from "./Components/Home/UserPage";
import { HomePage } from "./Components/Home/HomePage";
import { Analysis } from "./Components/Home/Analysis";
import { Contact } from "./Components/Home/Contact";
import { Professionals } from "./Components/Home/Professionals";
import { ProPage } from "./Components/Home/ProPage";
import { ResearchStorage } from "./Context/ResearchContext";
import { Inventories } from "./Components/Research/Inventories";
import { Words } from "./Components/Research/Words";
import { Logout } from "./Components/Login/Logout";
import { TestPage } from "./Components/Test/TestPage";
import { TestWords } from "./Components/Test/TestWords";
import { TestTrial } from "./Components/Test/TestTrial";
import { TestTrial2 } from "./Components/Test/TestTrial2";
import { Cloud1 } from "./Components/Cloud/Cloud1";
import { Cloud2 } from "./Components/Cloud/Cloud2";
import { Cloud3 } from "./Components/Cloud/Cloud3";
import { WordAnalysis } from "./Components/Analysis/WordAnalysis";
import { GlobalAnalysis } from "./Components/Analysis/GlobalAnalysis";
import { UserAnalysis } from "./Components/Analysis/UserAnalysis";

function App() {
  const [isMenuVisible, setIsMenuVisible] = useState(true);

  const toggleMenu = (e) => {
    setIsMenuVisible(!isMenuVisible);
  };

  const SidebarLayout = () => (
    <>
      <Header onMenuClick={toggleMenu} />
      <LeftMenu isMenuVisible={isMenuVisible} />
      <Content
        isMenuVisible={isMenuVisible}
        setIsMenuVisible={setIsMenuVisible}
      />
    </>
  );
  return (
    <UserStorage>
      {/* <ResearchStorage> */}
      <Routes>
        <Route path="login/*" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/signup" element={<Submission />} />
        <Route path="/home" element={<Login />} />
        <Route path="/test/words" element={<TestWords />} />
        <Route path="/test/trial" element={<TestTrial />} />
        <Route path="/test/trial2" element={<TestTrial2 />} />
        <Route path="/register" element={<LoginCreate />} />
        <Route path="/research" element={<Research />} />
        <Route path="/inventories" element={<Inventories />} />
        <Route path="/words" element={<Words />} />
        <Route path="/trial1" element={<Trial1 />} />
        <Route path="/trial2" element={<Trial2 />} />
        <Route path="/test1" element={<Test1 />} />
        <Route path="/test2" element={<Test2 />} />
        <Route element={<RequireAuth />}>
          <Route element={<SidebarLayout />}>
            <Route path="/" element={<UserPage />} />
            <Route path="/analysis" element={<Analysis />} />
            <Route path="/wordanalysis" element={<WordAnalysis />} />
            <Route path="/globalanalysis" element={<GlobalAnalysis />} />
            <Route path="/useranalysis" element={<UserAnalysis />} />
            <Route path="/cloud1" element={<Cloud1 />} />
            <Route path="/cloud2" element={<Cloud2 />} />
            <Route path="/cloud3" element={<Cloud3 />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/pros" element={<Professionals />} />
            <Route path="/pro/:id" element={<ProPage />} />
            <Route path="/account/*" element={<Account />} />
          </Route>
        </Route>
      </Routes>
      {/* </ResearchStorage> */}
    </UserStorage>
  );
}

export default App;
