import React from "react";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";
import css from "./SidebarCollapsed.module.css";

export const SidebarCollapsed = () => {
  const { userData } = React.useContext(UserContext);
  const { pathname } = useLocation();

  if (userData) {
    return (
      <div className={css.sidebar}>
        <div className={css.sidebarWrapper}>
          <div className={css.sidebarMenu}>
            <button className={css.userButton} />
            <ul className={css.sidebarList}>
              {pathname === "/" ? (
                <Link to="/" className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>home</i>
                  </li>
                </Link>
              ) : (
                <Link to="/" className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>home</i>
                  </li>
                </Link>
              )}

              {/* {pathname === "/research" ? (
                <Link to="/research" className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>route</i>
                  </li>
                </Link>
              ) : (
                <Link to="/research" className={css.link}>
                  <li className={css.sidebarListItem}>
                    <i className={`material-icons ${css.sidebarIcon}`}>route</i>
                  </li>
                </Link>
              )} */}

              {pathname === "/analysis" ? (
                <Link to="/analysis" className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      bar_chart
                    </i>
                  </li>
                </Link>
              ) : (
                <Link to="/analysis" className={css.link}>
                  <li className={css.sidebarListItem}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      bar_chart
                    </i>
                  </li>
                </Link>
              )}

              {pathname === "/contact" ? (
                <Link to="/contact" className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      perm_phone_msg
                    </i>
                  </li>
                </Link>
              ) : (
                <Link to="/contact" className={css.link}>
                  <li className={css.sidebarListItem}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      perm_phone_msg
                    </i>
                  </li>
                </Link>
              )}
            </ul>
          </div>
          <div
            className={`copyright ${
              userData.staff ? css.sidebarMenu : css.copyright
            } `}
          >
            <p>mairini.com</p>
            <p>©2022</p>
          </div>
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
