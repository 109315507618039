import React from "react";
import { Button } from "../Forms/Button";
import { Input } from "../Forms/Input";

export const Contact = () => {
  return (
    <div>
      <h2>Envie uma mensagem</h2>
      <form>
        <Input placeholder="Escreva sua mensagem" />
        <Button>Enviar</Button>
      </form>
    </div>
  );
};
