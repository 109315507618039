import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import css from "./UserPage.module.css";
import { UserContext } from "../../Context/UserContext";
import { CardTrial } from "../Layout/CardTrial";
import { getResearchFirebase } from "../../Utils/firebaseResearch";

export const UserPage = () => {
  const { user, userData, userResearch, setUserResearch, sessionTime } =
    useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      getResearchFirebase(user).then(function (result) {
        setUserResearch(result);
      });
    }
  }, [user]);

  if (!userData) {
    return <div>Loading...</div>;
  } else if (!userData.research) {
    return (
      <section className={css.container}>
        <div onClick={() => navigate("/research")}>
          <h2>Termo de Consentimento Livre e Esclarecido</h2>
        </div>
        {/* {console.log(userData)} */}
      </section>
    );
  } else if (userData.research) {
    return (
      <section className={css.container}>
        {/* <div>{sessionTime}</div> */}

        {userData.research && userResearch && (
          <div>
            <CardTrial
              icon=""
              title={"Questionário"}
              onClick={() => navigate("/inventories")}
              isActive={userResearch.phase === 1 ? true : false}
              // isActive={true}
            />
            <CardTrial
              icon=""
              title={"Avaliar palavras"}
              onClick={() => navigate("/words")}
              isActive={userResearch.phase === 2 ? true : false}
              // isActive={true}
            />
            <CardTrial
              icon=""
              title={"Treino 1"}
              attempts={
                userResearch.trialDay === 1
                  ? "tentativa: " + userResearch.Trial1attempts
                  : userResearch.trialDay === 2
                  ? "tentativa: " + userResearch.Trial1attemptsDay2
                  : "tentativa: " + userResearch.Trial1attemptsDay3
              }
              onClick={() => navigate("/trial1")}
              isActive={userResearch.phase === 3 ? true : false}
              // isActive={true}
            />
            <CardTrial
              icon=""
              title={"Teste 1"}
              onClick={() => navigate("/test1")}
              isActive={userResearch.phase === 4 ? true : false}
              // isActive={true}
            />
            <CardTrial
              icon=""
              title={"Treino 2"}
              attempts={
                userResearch.trialDay === 1
                  ? "tentativa: " + userResearch.Trial2attempts
                  : userResearch.trialDay === 2
                  ? "tentativa: " + userResearch.Trial2attemptsDay2
                  : "tentativa: " + userResearch.Trial2attemptsDay3
              }
              onClick={() => navigate("/trial2")}
              isActive={userResearch.phase === 5 ? true : false}
              // isActive={true}
            />
            <CardTrial
              icon=""
              title={"Teste 2"}
              onClick={() => navigate("/test2")}
              isActive={userResearch.phase === 6 ? true : false}
              // isActive={true}
            />
            <CardTrial
              icon=""
              title={"Fim"}
              onClick={() => navigate("/")}
              isActive={userResearch.phase === 7 ? true : false}
              // isActive={true}
            />
          </div>
        )}
      </section>
    );
  } else {
    return <div>Loading...</div>;
  }
};
