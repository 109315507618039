import { doc, setDoc, getDoc, getDocs, collection } from "firebase/firestore";
import { db } from "../firebaseConfig";

export const createResearchFirebase = async (resUser) => {
  await setDoc(doc(db, "wordMood", resUser.id), {
    id: resUser.id,
    name: resUser.name,
    email: resUser.email,
    age: resUser.age ? resUser.age : "",
    sex: resUser.sex ? resUser.sex : "",
    social: resUser.social ? resUser.social : "",
    education: resUser.education ? resUser.education : "",
    meds: resUser.meds ? resUser.meds : "",
    group: resUser.group ? resUser.group : "",
    trialDay: resUser.trialDay ? resUser.trialDay : 1,
    phase: resUser.phase ? resUser.phase : 0,
    DASS: resUser.DASS ? resUser.DASS : [],
    DASSdep: resUser.DASSdep ? resUser.DASSdep : 0,
    DASSanx: resUser.DASSanx ? resUser.DASSanx : 0,
    DASSstr: resUser.DASSstr ? resUser.DASSstr : 0,
    WordVal: resUser.WordVal ? resUser.WordVal : [],
    Trial1: resUser.Trial1 ? resUser.Trial1 : [],
    Trial1attempts: resUser.Trial1attempts ? resUser.Trial1attempts : 0,
    Trial1score: resUser.Trial1score ? resUser.Trial1score : 0,
    Test1: resUser.Test1 ? resUser.Test1 : [],
    Test1score: resUser.Test1score ? resUser.Test1score : 0,
    Trial2: resUser.Trial2 ? resUser.Trial2 : [],
    Trial2attempts: resUser.Trial2attempts ? resUser.Trial2attempts : 0,
    Trial2score: resUser.Trial2score ? resUser.Trial2score : 0,
    Test2: resUser.Test2 ? resUser.Test2 : [],
    Test2score: resUser.Test2score ? resUser.Test2score : 0,
    sessionTime: resUser.sessionTime ? resUser.sessionTime : 0,
    DASSDay2: resUser.DASSDay2 ? resUser.DASSDay2 : [],
    DASSdepDay2: resUser.DASSdepDay2 ? resUser.DASSdepDay2 : 0,
    DASSanxDay2: resUser.DASSanxDay2 ? resUser.DASSanxDay2 : 0,
    DASSstrDay2: resUser.DASSstrDay2 ? resUser.DASSstrDay2 : 0,
    WordValDay2: resUser.WordValDay2 ? resUser.WordValDay2 : [],
    Trial1Day2: resUser.Trial1Day2 ? resUser.Trial1Day2 : [],
    Trial1attemptsDay2: resUser.Trial1attemptsDay2
      ? resUser.Trial1attemptsDay2
      : 0,
    Trial1scoreDay2: resUser.Trial1scoreDay2 ? resUser.Trial1scoreDay2 : 0,
    Test1Day2: resUser.Test1Day2 ? resUser.Test1Day2 : [],
    Test1scoreDay2: resUser.Test1scoreDay2 ? resUser.Test1scoreDay2 : 0,
    Trial2Day2: resUser.Trial2Day2 ? resUser.Trial2Day2 : [],
    Trial2attemptsDay2: resUser.Trial2attemptsDay2
      ? resUser.Trial2attemptsDay2
      : 0,
    Trial2scoreDay2: resUser.Trial2scoreDay2 ? resUser.Trial2scoreDay2 : 0,
    Test2Day2: resUser.Test2Day2 ? resUser.Test2Day2 : [],
    Test2scoreDay2: resUser.Test2scoreDay2 ? resUser.Test2scoreDay2 : 0,
    sessionTimeDay2: resUser.sessionTimeDay2 ? resUser.sessionTimeDay2 : 0,
    DASSDay3: resUser.DASSDay3 ? resUser.DASSDay3 : [],
    DASSdepDay3: resUser.DASSdepDay3 ? resUser.DASSdepDay3 : 0,
    DASSanxDay3: resUser.DASSanxDay3 ? resUser.DASSanxDay3 : 0,
    DASSstrDay3: resUser.DASSstrDay3 ? resUser.DASSstrDay3 : 0,
    WordValDay3: resUser.WordValDay3 ? resUser.WordValDay3 : [],
    Trial1Day3: resUser.Trial1Day3 ? resUser.Trial1Day3 : [],
    Trial1attemptsDay3: resUser.Trial1attemptsDay3
      ? resUser.Trial1attemptsDay3
      : 0,
    Trial1scoreDay3: resUser.Trial1scoreDay3 ? resUser.Trial1scoreDay3 : 0,
    Test1Day3: resUser.Test1Day3 ? resUser.Test1Day3 : [],
    Test1scoreDay3: resUser.Test1scoreDay3 ? resUser.Test1scoreDay3 : 0,
    Trial2Day3: resUser.Trial2Day3 ? resUser.Trial2Day3 : [],
    Trial2attemptsDay3: resUser.Trial2attemptsDay3
      ? resUser.Trial2attemptsDay3
      : 0,
    Trial2scoreDay3: resUser.Trial2scoreDay3 ? resUser.Trial2scoreDay3 : 0,
    Test2Day3: resUser.Test2Day3 ? resUser.Test2Day3 : [],
    Test2scoreDay3: resUser.Test2scoreDay3 ? resUser.Test2scoreDay3 : 0,
    sessionTimeDay3: resUser.sessionTimeDay3 ? resUser.sessionTimeDay3 : 0,
  });
  console.log(`mood ${resUser.name} created`);
};

export const getResearchFirebase = async (user) => {
  const docRef = doc(db, "wordMood", user.uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log(`mood ${user.displayName} loaded`);
    return docSnap.data();
  } else {
    console.log("No such document");
  }
};

export const getResDataFirebase = async () => {
  const docRef = collection(db, "wordMood");
  const docSnap = await getDocs(docRef);
  if (docSnap) {
    return docSnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No such document");
  }
};
