import React, { useEffect, useContext } from "react";
import { UserContext } from "../../Context/UserContext";

export const Logout = () => {
  const { userLogout, setUser, setUserData } = useContext(UserContext);

  useEffect(() => {
    setUserData(false);
    setUser(false);
    return userLogout;
  }, [userLogout]);

  return <div>Logout</div>;
};
