import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import css from "../Research/Trial.module.css";
import { Head } from "../../Utils/Head";
import { newId } from "../../Utils/newId";
import { Button } from "../Forms/Button";

export const TestTrial2 = () => {
  const [choices, setChoices] = useState([]);
  const [showContext1, setShowContext1] = useState(false);
  const [showContext2, setShowContext2] = useState(false);
  const [showBlank, setShowBlank] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [choice, setChoice] = useState(false);
  const [newChoice, setNewChoice] = useState([
    "bem",
    "despreocupado",
    "quieto",
    "alegre",
    "culpa",
  ]);
  const [trialEnd, setTrialEnd] = useState(false);
  const [choiceOver, setChoiceOver] = useState(false);
  const [choiceEnter, setChoiceEnter] = useState(false);
  const [consequence, setConsequence] = useState(false);
  const dragItem = useRef();
  const dragNode = useRef();
  const dropItem = useRef();
  const navigate = useNavigate();
  const [startPhase, setStartPhase] = useState(false);
  const [endPhase, setEndPhase] = useState(false);
  const [reactionTime, setReactionTime] = useState(Date.now());
  const [reactionTimeList, setReactionTimeList] = useState(
    new Array(2).fill(0)
  );
  const [score, setScore] = useState(0);
  const [index, setIndex] = useState(0);
  const phase = {
    id: newId(),
    index: 0,
    trials: [
      {
        sample: "Eu me sinto",
        context1: "triste",
        context2: "ANTÔNIMO",
        options: ["bem", "despreocupado", "quieto", "alegre", "culpa"],
        correct: "alegre",
      },
      {
        sample: "Nunca fico",
        context1: "ansioso",
        context2: "SINÔNIMO",
        options: ["culpado", "fracassado", "mal", "agoniado", "triste"],
        correct: "agoniado",
      },
    ],
  };

  useEffect(() => {
    if (startPhase) {
      setTimeout(function () {
        setShowBlank(true);
      }, 1000);
      setTimeout(function () {
        setShowContext2(true);
      }, 2000);
      setTimeout(function () {
        setShowContext1(true);
      }, 3000);
      setTimeout(function () {
        setShowOptions(true);
      }, 4000);
    }
  }, [startPhase]);

  const handleDragStart = (e, position, word) => {
    console.log("draging...", position);
    dragItem.current = position;
    dragNode.current = e.target;
    dragNode.current.addEventListener("dragend", handleDragEnd);
    setChoice(word);
    setTimeout(() => {
      setDragging(word);
    }, 0);
  };

  const handleDragEnter = (e, params) => {
    console.log("Entering drag...");
    dropItem.current = e.target;
    dropItem.current.addEventListener("dragover", handleDragOver);
    dropItem.current.addEventListener("dragleave", handleDragLeave);
    dropItem.current.addEventListener("drop", handleDrop);

    // const currentItem = dragItem.current;
    // if (e.target !== dragNode.current) {
    //   console.log("TARGET IS NOT THE SAME");
    //   setChoices((oldChoices) => {
    //     let newChoices = JSON.parse(JSON.stringify(oldChoices));
    //     newChoices[params.grpI].items.splice(
    //       params.item,
    //       0,
    //       newChoices[currentItem.grpI].items.splice(currentItem.itemI, 1)[0]
    //     );
    //     dragItem.current = params;
    //     return newChoices;
    //   });
    // }
  };

  const handleDragOver = (e) => {
    console.log("Over drag...");
    const oldL = [...phase.trials[index].options];
    setChoices(oldL);
    e.stopPropagation();
    e.preventDefault();
    setChoiceOver(true);
  };

  const handleDragLeave = () => {
    console.log("Leave drag...");
    setChoiceOver(false);
  };

  const handleDrop = () => {
    console.log("Dropped");
    phase.trials[index].options[dragItem.current] = "";
    setNewChoice(phase.trials[index].options);
    setChoiceEnter(dragging);
    setTrialEnd(true);
  };

  const handleDragEnd = () => {
    console.log("Ending drag...", dragItem.current);
    setDragging(false);
    dragNode.current.removeEventListener("dragend", handleDragEnd);
    dropItem.current.removeEventListener("dragover", handleDragOver);
    dropItem.current.removeEventListener("drop", handleDrop);
    dragItem.current = null;
    dragNode.current = null;

    reactionTimeList[index] = Date.now() - reactionTime;
  };

  const getStyles = (itemStyle) => {
    const currentItem = dragItem.current;
    if (currentItem === itemStyle) {
      return css.cardDraging;
    }
    return css.card;
  };

  const getDropzone = (i) => {
    if (choiceEnter && dragItem.current === null) {
      return (
        <div
          key={i}
          className={dragging ? getStyles(i) : css.card}
          draggable={trialEnd ? false : true}
          onDragStart={(e) => handleDragStart(e, i)}
        >
          {choiceEnter}
        </div>
      );
    } else {
      if (choiceOver) {
        return (
          <div
            key={i}
            className={css.dropzoneActive}
            onDrop={(e) => handleDrop(e, i)}
          ></div>
        );
      } else {
        return (
          <div
            key={i}
            className={css.dropzone}
            onDragEnter={(e) => handleDragEnter(e, i)}
            onDrop={(e) => handleDrop(e, i)}
          ></div>
        );
      }
    }
  };

  const restart = () => {
    phase.trials[index].options = choices;
    setNewChoice(choices);
    setDragging(false);
    setChoiceEnter(false);
    setTrialEnd(false);
    setChoiceOver(false);
  };

  const checkCorrect = () => {
    let correct = false;
    if (phase.trials[index].correct === choice) {
      correct = "correct";
      setScore(score + 1);
    } else {
      correct = "incorrect";
    }
    setTimeout(() => {
      phaseEnded() ? finish() : next();
    }, 1500);
    return setConsequence(correct);
  };

  function phaseEnded() {
    if (index + 1 === phase.trials.length) {
      return true;
    } else {
      return false;
    }
  }

  function next() {
    setConsequence(false);
    setIndex(index + 1);
    setDragging(false);
    setChoiceEnter(false);
    setTrialEnd(false);
    setChoiceOver(false);
    setShowContext1(false);
    setShowContext2(false);
    setShowBlank(false);
    setShowOptions(false);
    setNewChoice(phase.trials[index + 1].options);
    setChoices(phase.trials[index + 1].options);
    setTimeout(function () {
      setShowBlank(true);
    }, 1000);
    setTimeout(function () {
      setShowContext2(true);
    }, 2000);
    setTimeout(function () {
      setShowContext1(true);
    }, 3000);
    setTimeout(function () {
      setShowOptions(true);
    }, 4000);
  }

  const finish = () => {
    setConsequence(false);
    setEndPhase(true);
  };

  if (!startPhase) {
    return (
      <div className={css.container}>
        <section className={css.sampleGrid}>
          <div className={css.containerText}>
            <h1>Instruções</h1>
            <p>
              A seguir, na parte superior da tela, serão apresentadas algumas
              palavras à esquerda, um campo em branco ao meio, uma palavra ao
              lado do campo em branco e outra palavra à direita. Na parte
              inferior serão apresentadas 5 palavras.
            </p>
            <p>
              Nesta etapa, você irá escolher a palavra que melhor se encaixa no
              campo em branco. Para fazer a escolha você precisa clicar na
              palavra e arrastar com o mouse até o campo em branco. Em seguida,
              poderá escolher entre recomeçar ou terminar a escolha por meio de
              duas opções que aparecerão no campo inferior da tela.
            </p>
            <p>
              Após terminar a tarefa, uma mensagens indicando se a escolha foi
              correta ou incorreta aparecerá na parte inferior da tela durante
              1,5 segundos. Em seguida, uma nova tarefa será apresentada, até
              que essa etapa termine.
            </p>
            <div className={css.buttonText} onClick={() => setStartPhase(true)}>
              Começar
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <div className={css.container}>
        <Head title="Trial" />
        {!endPhase ? (
          <>
            <section className={css.sampleGrid}>
              <div className={css.sample}>
                <div className={css.card}>{phase.trials[index].sample}</div>
                {showBlank ? (
                  <div className={css.blank}>{getDropzone("")}</div>
                ) : (
                  <div className={css.hidden}></div>
                )}
                {showContext2 ? (
                  <div>
                    <h2>{phase.trials[index].context2}</h2>
                  </div>
                ) : (
                  <div className={css.hidden}></div>
                )}
                {showContext1 ? (
                  <div className={css.card}>{phase.trials[index].context1}</div>
                ) : (
                  <div className={css.hidden}></div>
                )}
              </div>
            </section>
            <section className={css.optionsGrid}>
              {showOptions ? (
                <div id="div1" className={css.options}>
                  {newChoice.map((word, i) => (
                    <div
                      key={i}
                      className={
                        word
                          ? dragging
                            ? getStyles(i)
                            : css.card
                          : css.dropzone
                      }
                      draggable={trialEnd && choiceEnter ? false : true}
                      onDragStart={(e) => handleDragStart(e, i, word)}
                    >
                      {word}
                    </div>
                  ))}
                </div>
              ) : (
                <div></div>
              )}
            </section>
            <section className={css.finishGrid}>
              {trialEnd && choiceEnter && !consequence && (
                <div className={css.finish}>
                  <div className={css.cardButton} onClick={() => restart()}>
                    Recomeçar
                  </div>
                  <div
                    className={css.cardButton}
                    onClick={() => checkCorrect()}
                  >
                    Terminar
                  </div>
                </div>
              )}
            </section>
            <section className={css.finishGrid}>
              {trialEnd && choiceEnter && consequence && (
                <>
                  {consequence === "correct" ? (
                    <div className={css.consequence}>
                      <div className={css.cardCorrect}>Correto</div>
                    </div>
                  ) : (
                    <div className={css.consequence}>
                      <div className={css.cardIncorrect}>Incorreto</div>
                    </div>
                  )}
                </>
              )}
            </section>
          </>
        ) : (
          <div className={css.containerText}>
            <h1>Resultados</h1>
            <p>Acertos: {score}</p>
            <p>
              Porcentagem de acertos: {(score * 100) / phase.trials.length} %
            </p>
            {reactionTimeList.map((item, i) => (
              <div key={i}>
                <p>
                  <strong>{item.word}</strong>
                </p>

                <p>
                  Tempo de reação {i + 1}: {item} ms
                </p>
                <hr />
              </div>
            ))}
            <Button onClick={() => navigate("/")}>Retornar</Button>
          </div>
        )}
      </div>
    );
  }
};
