import React, { useEffect, useState } from "react";
import { getResDataFirebase } from "../../Utils/firebaseResearch";

export const GlobalAnalysis = () => {
  const [resData, setResData] = useState([]);
  const [wordNameList, setWordNameList] = useState([]);
  const [showDetails, setShowDetails] = useState(true);

  useEffect(() => {
    getResDataFirebase().then(function (result) {
      setResData(result);
      if (result) {
        result.map((u) =>
          u.WordVal.map(
            (w) => wordNameList.indexOf(w.word) < 0 && wordNameList.push(w)
          )
        );
        setWordNameList([...wordNameList]);
      }
    });
  }, []);

  if (showDetails) {
    return (
      <div>
        <h1>Data Analysis</h1>
        <button
          className="defaultButton"
          onClick={() => setShowDetails(!showDetails)}
        >
          show details
        </button>
        {wordNameList.map((w, i) => (
          <div key={i}>
            <strong>{w.word}</strong>
            <br />
            Valence: {w.valence}
            <br />
            Reaction Time:{" "}
            {(Math.round((w.reactionTime / 1000) * 100) / 100).toFixed(2)}
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div>
        <h1>Data Analysis</h1>
        <button
          className="defaultButton"
          onClick={() => setShowDetails(!showDetails)}
        >
          show details
        </button>
        {wordNameList.map((w, i) => (
          <div key={i}>
            <strong>{w.word}</strong>
          </div>
        ))}
      </div>
    );
  }
};
