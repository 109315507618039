import React, { useContext, useEffect, useState } from "react";

import css from "./Analysis.module.css";
import { UserContext } from "../../Context/UserContext";
import { Canvas } from "../../Hooks/Canvas";

export const Analysis = () => {
  const { userResearch } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const xPadding = 30;
  const yPadding = 30;
  const trialValue = {
    values: [
      { X: "Dia 1", Y: 100 },
      { X: "Dia 2", Y: 100 },
      { X: "Dia 3", Y: 100 },
    ],
  };
  const [trial1, setTrial1] = useState({
    values: [
      { X: "Dia 1", Y: 100 },
      { X: "Dia 2", Y: 100 },
      { X: "Dia 3", Y: 100 },
    ],
  });
  const [trial2, setTrial2] = useState({
    values: [
      { X: "Dia 1", Y: 100 },
      { X: "Dia 2", Y: 100 },
      { X: "Dia 3", Y: 100 },
    ],
  });
  const [test1, setTest1] = useState({
    values: [
      { X: "Dia 1", Y: 100 },
      { X: "Dia 2", Y: 100 },
      { X: "Dia 3", Y: 100 },
    ],
  });
  const [test2, setTest2] = useState({
    values: [
      { X: "Dia 1", Y: 100 },
      { X: "Dia 2", Y: 100 },
      { X: "Dia 3", Y: 100 },
    ],
  });
  let DASSValue = {
    values: [
      { X: "Dia 1", Y: 0 },
      { X: "Dia 2", Y: 10 },
      { X: "Dia 3", Y: 20 },
    ],
  };
  const [DASSanx, setDASSanx] = useState({
    values: [
      { X: "Ansiedade", Y: 9 },
      { X: "Depressão", Y: 8 },
      { X: "Estresse", Y: 6 },
    ],
  });
  const [DASSdep, setDASSdep] = useState({
    values: [
      { X: "Ansiedade", Y: 9 },
      { X: "Depressão", Y: 8 },
      { X: "Estresse", Y: 6 },
    ],
  });
  const [DASSstr, setDASSstr] = useState({
    values: [
      { X: "Ansiedade", Y: 9 },
      { X: "Depressão", Y: 8 },
      { X: "Estresse", Y: 6 },
    ],
  });

  useEffect(() => {
    if (userResearch) {
      trial1.values[0].Y = userResearch.Trial1score;
      trial1.values[1].Y = userResearch.Trial1scoreDay2;
      trial1.values[2].Y = userResearch.Trial1scoreDay3;
      setTrial1(trial1);
      test1.values[0].Y = userResearch.Test1score;
      test1.values[1].Y = userResearch.Test1scoreDay2;
      test1.values[2].Y = userResearch.Test1scoreDay3;
      setTest1(test1);
      trial2.values[0].Y = userResearch.Trial2score;
      trial2.values[1].Y = userResearch.Trial2scoreDay2;
      trial2.values[2].Y = userResearch.Trial2scoreDay3;
      setTrial2(trial2);
      test2.values[0].Y = userResearch.Test2score;
      test2.values[1].Y = userResearch.Test2scoreDay2;
      test2.values[2].Y = userResearch.Test2scoreDay3;
      setTest2(test2);
      DASSanx.values[0].Y = userResearch.DASSanx;
      DASSanx.values[1].Y = userResearch.DASSanxDay2;
      DASSanx.values[2].Y = userResearch.DASSanxDay3;
      setDASSanx(DASSanx);
      DASSdep.values[0].Y = userResearch.DASSdep;
      DASSdep.values[1].Y = userResearch.DASSdepDay2;
      DASSdep.values[2].Y = userResearch.DASSdepDay3;
      setDASSdep(DASSdep);
      DASSstr.values[0].Y = userResearch.DASSstr;
      DASSstr.values[1].Y = userResearch.DASSstrDay2;
      DASSstr.values[2].Y = userResearch.DASSstrDay3;
      setDASSstr(DASSstr);
      setLoading(true);
    }
  }, [userResearch]);

  const DASS = (c) => {
    c.clearRect(0, 0, 500, 400);
    function getMaxY() {
      var max = 0;

      for (var i = 0; i < DASSValue.values.length; i++) {
        if (DASSValue.values[i].Y > max) {
          max = DASSValue.values[i].Y;
        }
      }

      max += 10 - (max % 10);
      return max;
    }

    function getXPixel(val) {
      return (
        ((c.canvas.width - xPadding) / DASSValue.values.length) * val +
        xPadding * 1.5
      );
    }

    function getYPixel(val) {
      return (
        c.canvas.height -
        ((c.canvas.height - yPadding) / getMaxY()) * val -
        yPadding
      );
    }

    c.lineWidth = 2;
    c.strokeStyle = "#333";
    c.font = "italic 8pt sans-serif";
    c.textAlign = "center";

    c.beginPath();
    c.moveTo(xPadding, 0 + xPadding);
    c.lineTo(xPadding, c.canvas.height - yPadding);
    c.lineTo(c.canvas.width, c.canvas.height - yPadding);
    c.stroke();

    // X axis Down
    for (var i = 0; i < DASSValue.values.length; i++) {
      c.fillStyle = "black";
      c.fillText(
        DASSValue.values[i].X,
        getXPixel(i),
        c.canvas.height - yPadding + 20
      );
    }
    // c.textAlign = "right";
    // c.textBaseline = "middle";
    const colorArr = ["blue", "red", "green"];

    // X axis Up
    for (var i = 0; i < DASSValue.values.length; i++) {
      c.fillStyle = colorArr[i];
      c.fillText(
        DASSanx.values[i].X,
        getXPixel(i),
        c.canvas.height - yPadding - 150
      );
      // c.beginPath();
      // c.arc(
      //   getXPixel(i),
      //   c.canvas.height - yPadding - 150,
      //   4,
      //   0,
      //   Math.PI * 2,
      //   true
      // );
      // c.fill();
    }

    // Y axis
    for (var i = 0; i < getMaxY(); i += 10) {
      c.fillStyle = "black";
      c.fillText(i, xPadding - 10, getYPixel(i));
    }
    // Line1
    c.strokeStyle = "blue";
    c.beginPath();
    c.moveTo(getXPixel(0), getYPixel(DASSanx.values[0].Y));

    for (var i = 1; i < DASSValue.values.length; i++) {
      c.lineTo(getXPixel(i), getYPixel(DASSanx.values[i].Y));
    }
    c.stroke();

    // Line2
    c.strokeStyle = "red";
    c.beginPath();
    c.moveTo(getXPixel(0), getYPixel(DASSdep.values[0].Y));

    for (var i = 1; i < DASSValue.values.length; i++) {
      c.lineTo(getXPixel(i), getYPixel(DASSdep.values[i].Y));
    }
    c.stroke();

    // Line3
    c.strokeStyle = "green";
    c.beginPath();
    c.moveTo(getXPixel(0), getYPixel(DASSstr.values[0].Y));

    for (var i = 1; i < DASSValue.values.length; i++) {
      c.lineTo(getXPixel(i), getYPixel(DASSstr.values[i].Y));
    }
    c.stroke();
  };

  const trial1Score = (c) => {
    c.clearRect(0, 0, 500, 400);
    function getMaxY() {
      var max = 0;

      for (var i = 0; i < trialValue.values.length; i++) {
        if (trialValue.values[i].Y > max) {
          max = trialValue.values[i].Y;
        }
      }

      max += 10 - (max % 10);
      return max;
    }

    function getXPixel(val) {
      return (
        ((c.canvas.width - xPadding) / trialValue.values.length) * val +
        xPadding * 1.5
      );
    }

    function getYPixel(val) {
      return (
        c.canvas.height -
        ((c.canvas.height - yPadding) / getMaxY()) * val -
        yPadding
      );
    }

    c.lineWidth = 2;
    c.strokeStyle = "#333";
    c.font = "italic 8pt sans-serif";
    c.textAlign = "center";

    c.beginPath();
    c.moveTo(xPadding, 0);
    c.lineTo(xPadding, c.canvas.height - yPadding);
    c.lineTo(c.canvas.width, c.canvas.height - yPadding);
    c.stroke();
    // Y axis
    for (var i = 0; i < trialValue.values.length; i++) {
      c.fillText(
        trialValue.values[i].X,
        getXPixel(i),
        c.canvas.height - yPadding + 20
      );
    }
    c.textAlign = "right";
    c.textBaseline = "middle";
    // X axis
    for (var i = 0; i < getMaxY(); i += 10) {
      c.fillText(i, xPadding - 10, getYPixel(i));
    }
    // Line
    c.strokeStyle = "blue";
    c.beginPath();
    c.moveTo(getXPixel(0), getYPixel(trial1.values[0].Y));

    for (var i = 1; i < trial1.values.length; i++) {
      c.lineTo(getXPixel(i), getYPixel(trial1.values[i].Y));
    }
    c.stroke();
  };

  const trial2Score = (c) => {
    c.clearRect(0, 0, 500, 400);
    function getMaxY() {
      var max = 0;

      for (var i = 0; i < trialValue.values.length; i++) {
        if (trialValue.values[i].Y > max) {
          max = trialValue.values[i].Y;
        }
      }

      max += 10 - (max % 10);
      return max;
    }

    function getXPixel(val) {
      return (
        ((c.canvas.width - xPadding) / trialValue.values.length) * val +
        xPadding * 1.5
      );
    }

    function getYPixel(val) {
      return (
        c.canvas.height -
        ((c.canvas.height - yPadding) / getMaxY()) * val -
        yPadding
      );
    }

    c.lineWidth = 2;
    c.strokeStyle = "#333";
    c.font = "italic 8pt sans-serif";
    c.textAlign = "center";

    c.beginPath();
    c.moveTo(xPadding, 0);
    c.lineTo(xPadding, c.canvas.height - yPadding);
    c.lineTo(c.canvas.width, c.canvas.height - yPadding);
    c.stroke();
    // Y axis
    for (var i = 0; i < trialValue.values.length; i++) {
      c.fillText(
        trialValue.values[i].X,
        getXPixel(i),
        c.canvas.height - yPadding + 20
      );
    }
    c.textAlign = "right";
    c.textBaseline = "middle";
    // X axis
    for (var i = 0; i < getMaxY(); i += 10) {
      c.fillText(i, xPadding - 10, getYPixel(i));
    }
    // Line
    c.strokeStyle = "blue";
    c.beginPath();
    c.moveTo(getXPixel(0), getYPixel(trial2.values[0].Y));

    for (var i = 1; i < trial2.values.length; i++) {
      c.lineTo(getXPixel(i), getYPixel(trial2.values[i].Y));
    }
    c.stroke();
  };

  const test1Score = (c) => {
    c.clearRect(0, 0, 500, 400);
    function getMaxY() {
      var max = 0;

      for (var i = 0; i < trialValue.values.length; i++) {
        if (trialValue.values[i].Y > max) {
          max = trialValue.values[i].Y;
        }
      }

      max += 10 - (max % 10);
      return max;
    }

    function getXPixel(val) {
      return (
        ((c.canvas.width - xPadding) / trialValue.values.length) * val +
        xPadding * 1.5
      );
    }

    function getYPixel(val) {
      return (
        c.canvas.height -
        ((c.canvas.height - yPadding) / getMaxY()) * val -
        yPadding
      );
    }

    c.lineWidth = 2;
    c.strokeStyle = "#333";
    c.font = "italic 8pt sans-serif";
    c.textAlign = "center";

    c.beginPath();
    c.moveTo(xPadding, 0);
    c.lineTo(xPadding, c.canvas.height - yPadding);
    c.lineTo(c.canvas.width, c.canvas.height - yPadding);
    c.stroke();
    // X axis
    for (var i = 0; i < trialValue.values.length; i++) {
      c.fillText(
        trialValue.values[i].X,
        getXPixel(i),
        c.canvas.height - yPadding + 20
      );
    }
    c.textAlign = "right";
    c.textBaseline = "middle";
    // Y axis
    for (var i = 0; i < getMaxY(); i += 10) {
      c.fillText(i, xPadding - 10, getYPixel(i));
    }
    // Line
    c.strokeStyle = "blue";
    c.beginPath();
    c.moveTo(getXPixel(0), getYPixel(test1.values[0].Y));

    for (var i = 1; i < test1.values.length; i++) {
      c.lineTo(getXPixel(i), getYPixel(test1.values[i].Y));
    }
    c.stroke();
  };

  const test2Score = (c) => {
    c.clearRect(0, 0, 500, 400);
    function getMaxY() {
      var max = 0;

      for (var i = 0; i < trialValue.values.length; i++) {
        if (trialValue.values[i].Y > max) {
          max = trialValue.values[i].Y;
        }
      }

      max += 10 - (max % 10);
      return max;
    }

    function getXPixel(val) {
      return (
        ((c.canvas.width - xPadding) / trialValue.values.length) * val +
        xPadding * 1.5
      );
    }

    function getYPixel(val) {
      return (
        c.canvas.height -
        ((c.canvas.height - yPadding) / getMaxY()) * val -
        yPadding
      );
    }

    c.lineWidth = 2;
    c.strokeStyle = "#333";
    c.font = "italic 8pt sans-serif";
    c.textAlign = "center";

    c.beginPath();
    c.moveTo(xPadding, 0);
    c.lineTo(xPadding, c.canvas.height - yPadding);
    c.lineTo(c.canvas.width, c.canvas.height - yPadding);
    c.stroke();
    // X axis
    for (var i = 0; i < trialValue.values.length; i++) {
      c.fillText(
        trialValue.values[i].X,
        getXPixel(i),
        c.canvas.height - yPadding + 20
      );
    }
    c.textAlign = "right";
    c.textBaseline = "middle";
    // Y axis
    for (var i = 0; i < getMaxY(); i += 10) {
      c.fillText(i, xPadding - 10, getYPixel(i));
    }
    // Line
    c.strokeStyle = "blue";
    c.beginPath();
    c.moveTo(getXPixel(0), getYPixel(test2.values[0].Y));

    for (var i = 1; i < test2.values.length; i++) {
      c.lineTo(getXPixel(i), getYPixel(test2.values[i].Y));
    }
    c.stroke();
  };

  if (userResearch && loading) {
    return (
      <div className={css.chartWrapper}>
        <div className={css.days}>
          <div>
            <h4>Dia 1</h4>
            <p>Ansiedade {userResearch.DASSanx}</p>
            <p>Depressão {userResearch.DASSdep}</p>
            <p>Estresse {userResearch.DASSstr}</p>
            <p>Treino 1: {Number(userResearch.Trial1score).toFixed(2)}%</p>
            <p>Tentativas: {userResearch.Trial1attempts}</p>
            <p>Teste 1: {Number(userResearch.Test1score).toFixed(2)}%</p>
            <p>Treino 2: {Number(userResearch.Trial2score).toFixed(2)}%</p>
            <p>Tentativas: {userResearch.Trial2attempts}</p>
            <p>Teste 2: {Number(userResearch.Test2score).toFixed(2)}%</p>
          </div>
          <div>
            <h4>Dia 2</h4>
            <p>Ansiedade {userResearch.DASSanxDay2}</p>
            <p>Depressão {userResearch.DASSdepDay2}</p>
            <p>Estresse {userResearch.DASSstrDay2}</p>
            <p>Treino 1: {Number(userResearch.Trial1scoreDay2).toFixed(2)}%</p>
            <p>Tentativas: {userResearch.Trial1attemptsDay2}</p>
            <p>Teste 1: {Number(userResearch.Test1scoreDay2).toFixed(2)}%</p>
            <p>Treino 2: {Number(userResearch.Trial2scoreDay2).toFixed(2)}%</p>
            <p>Tentativas: {userResearch.Trial2attemptsDay2}</p>
            <p>Teste 2: {Number(userResearch.Test2scoreDay2).toFixed(2)}%</p>
          </div>
          <div>
            <h4>Dia 3</h4>
            <p>Ansiedade {userResearch.DASSanxDay3}</p>
            <p>Depressão {userResearch.DASSdepDay3}</p>
            <p>Estresse {userResearch.DASSstrDay3}</p>
            <p>Treino 1: {Number(userResearch.Trial1scoreDay3).toFixed(2)}%</p>
            <p>Tentativas: {userResearch.Trial1attemptsDay3}</p>
            <p>Teste 1: {Number(userResearch.Test1scoreDay3).toFixed(2)}%</p>
            <p>Treino 2: {Number(userResearch.Trial2scoreDay3).toFixed(2)}%</p>
            <p>Tentativas: {userResearch.Trial2attemptsDay3}</p>
            <p>Teste 2: {Number(userResearch.Test2scoreDay3).toFixed(2)}%</p>
          </div>
        </div>

        <div>
          <Canvas draw={DASS} width="300" height="200" />
        </div>
        <div>
          <h4>Treino 1</h4>
          <Canvas draw={trial1Score} width="300" height="200" />
        </div>
        <div>
          <h4>Teste 1</h4>
          <Canvas draw={test1Score} width="300" height="200" />
        </div>
        <div>
          <h4>Treino 2</h4>
          <Canvas draw={trial2Score} width="300" height="200" />
        </div>
        <div>
          <h4>Teste 2</h4>
          <Canvas draw={test2Score} width="300" height="200" />
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
