import css from "./Chart.module.css";
import ChartElement from "./ChartElement";
import Connection from "./Connection";
import Toolbar from "./Toolbar";

function Chart(props) {
  const WIDTH = props.width / props.zoom;
  const HEIGHT = props.height / props.zoom;
  const R = 50;

  const getChildren = (list, parent, elements, dPhi) => {
    const children = list.filter((item) => item.parentId === parent.id);
    let listCounter = [0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (let i = 0; i < children.length; i++) {
      let item = children[i];
      const listLevel = children.filter((o) => o.level === item.level);
      let phi =
        (listCounter[item.level - 1] * dPhi) / listLevel.length + parent.phi;
      const isLeftSide = phi > Math.PI / 2 && phi < (3 * Math.PI) / 2;
      const element = {
        id: item.id,
        title: item.title,
        color: item.color,
        level: item.level,
        x: parent.x + R * item.level * 1.5 * Math.cos(phi),
        y: parent.y + R * item.level * Math.sin(phi),
        // x: parent.x + R * Math.cos(phi),
        // y: parent.y + R * Math.sin(phi),
        phi,
        px: parent.x,
        py: parent.y,
        isLeftSide,
      };
      elements.push(element);
      getChildren(list, element, elements, dPhi / children.length);
      item.level === 1 && listCounter[0]++;
      item.level === 2 && listCounter[1]++;
      item.level === 3 && listCounter[2]++;
      item.level === 4 && listCounter[3]++;
      item.level === 5 && listCounter[4]++;
      item.level === 6 && listCounter[5]++;
      item.level === 7 && listCounter[6]++;
      item.level === 8 && listCounter[7]++;
      item.level === 9 && listCounter[8]++;
    }
  };

  const setElements = (list) => {
    const elements = [];
    const x0 = WIDTH / 2;
    const y0 = HEIGHT / 2;
    const root = list.find((item) => item.level === 0);
    const rootElement = {
      id: root.id,
      title: root.title,
      level: root.level,
      x: x0,
      y: y0,
      phi: 0,
    };
    elements.push(rootElement);
    getChildren(list, rootElement, elements, 2 * Math.PI);
    return elements;
  };

  const zoomMenu = [
    { name: "zoomIn", onClick: (e) => props.onZoomIn(e) },
    { name: "zoomOut", onClick: (e) => props.onZoomOut(e) },
    { name: "zoomReset", onClick: (e) => props.onZoomReset(e) },
    // {
    //   name: "fullscreen",
    //   onClick: (e) => {
    //     e.preventDefault();
    //     navigate(`/study/mindmap/fullscreen/${props.mindmapId}`);
    //   },
    // },
  ];

  const elements = setElements(props.list);
  return (
    <div className={css.container}>
      <Toolbar
        list={zoomMenu}
        type="default"
        location={["horizontal", "right", "top"]}
      />
      <svg
        viewBox={`${props.x} ${props.y} ${WIDTH} ${HEIGHT}`}
        onMouseDown={(e) => props.onMouseDown(e)}
        onMouseMove={(e) => props.onMouseMove(e)}
        onMouseUp={() => props.onMouseUp()}
        xmlns="http://www.w3.org/2000/svg"
      >
        <Connection list={elements} />
        {elements.map((element) => (
          <ChartElement
            key={element.id}
            title={element.title}
            color={element.color}
            onClick={props.onClick}
            id={element.id}
            phi={element.phi}
            level={element.level}
            x={element.x}
            y={element.y}
            isSelected={element.id === props.id}
            px={element.px}
            py={element.py}
          />
        ))}
      </svg>
    </div>
  );
}

export default Chart;
