import React from "react";
import { Link, useNavigate } from "react-router-dom";

import css from "./header.module.css";
import { ReactComponent as Logo } from "../../assets/mairini-logo.svg";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import { UserContext } from "../../Context/UserContext";

export const Header = (props) => {
  const { user, userLogout, userData } = React.useContext(UserContext);
  const [menuButton, setMenuButton] = React.useState(false);
  const navigate = useNavigate();

  if (!user) {
    return (
      <div className={css.topbar}>
        <div className={css.topbarWrapper}>
          <div className={css.topLeft}>
            <div className={css.logo}>
              <Link className={css.logo} to="/" aria-label="Mairini - Home">
                {/* <Logo /> */}
                {/* Efeito de relações derivadas de similar e oposto e de sinônimos
                e antônimos em relatos verbais de sintomas depressivos */}
              </Link>
            </div>
          </div>
          <div className={css.topRight}>
            <div className={css.menuContainer}>
              <button
                className="defaultButton"
                onClick={() => navigate("/login/")}
              >
                Entrar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (!userData) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className={css.topbar}>
        <div className={css.topbarWrapper}>
          <div className={css.topLeft}>
            <div className={css.logo}>
              <Link className={css.logo} to="/" aria-label="Mairini - Home">
                {/* <Logo /> */}
                {/* Efeito de relações derivadas de similar e oposto e de sinônimos
                e antônimos em relatos verbais de sintomas depressivos */}
              </Link>
              <div className={css.tollbar}></div>
            </div>
          </div>
          <div className={css.topRight}>
            <div className={css.menuContainerIn}>
              <OutsideAlerter setMenuButton={setMenuButton}>
                <i
                  onClick={() => setMenuButton(!menuButton)}
                  className="material-icons materialIcon"
                >
                  settings
                </i>
                {menuButton && (
                  <nav className={css.navUserButton}>
                    <ul>
                      <Link to="/account">
                        <li>
                          <button onClick={() => setMenuButton(!menuButton)}>
                            Conta
                          </button>
                        </li>
                      </Link>
                      <li>
                        <button onClick={userLogout}>Sair</button>
                      </li>
                    </ul>
                  </nav>
                )}
              </OutsideAlerter>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
