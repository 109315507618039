import React, { useRef, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import css from "./Research.module.css";
import { UserContext } from "../../Context/UserContext";
import { Head } from "../../Utils/Head";
import { Input } from "../Forms/Input";
import { Button } from "../Forms/Button";
import { useForm } from "../../Hooks/useForm";
import { createResearchFirebase } from "../../Utils/firebaseResearch";
import { updateUserFirebase } from "../../Utils/firebaseUser";

export const Research = () => {
  const { userData, setUserData, setUserResearch, sessionTime } =
    useContext(UserContext);
  const navigate = useNavigate();
  const ref = useRef(null);
  const [confirmation, setConfirmation] = useState(false);
  const [index, setIndex] = useState(0);
  const name = useForm();
  const email = useForm();
  const age = useForm();
  const civil = useForm();
  const education = useForm();
  const meds = useForm();
  const [sex, setSex] = useState(false);
  const date = useForm();
  const [alertName, setAlertName] = React.useState("");
  const [alertEmail, setAlertEmail] = React.useState("");
  const [alertAge, setAlertAge] = React.useState("");
  const [alertCivil, setAlertCivil] = React.useState("");
  const [alertEducation, setAlertEducation] = React.useState("");
  const [alertMeds, setAlertMeds] = React.useState("");
  const [alertSex, setAlertSex] = React.useState("");
  const [alertDate, setAlertDate] = React.useState("");

  useEffect(() => {
    if (userData) {
      name.setValue(userData.displayName);
      email.setValue(userData.email);
      date.setValue(dayjs().format("DD/MM/YY"));
    }
  }, [userData, name, email, date]);

  function nextPage() {
    setConfirmation(false);
    setIndex(index + 1);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }

  function handleValidation() {
    let formIsValid = true;
    if (!name.value) {
      formIsValid = false;
      setAlertName("É preciso um Nome completo");
    } else {
      setAlertName("");
    }
    if (!email.value) {
      formIsValid = false;
      setAlertEmail("É preciso um email");
    } else {
      setAlertEmail("");
    }
    if (!age.value) {
      formIsValid = false;
      setAlertAge("É preciso uma Idade");
    } else {
      setAlertAge("");
    }
    if (!civil.value) {
      formIsValid = false;
      setAlertCivil("É preciso preencher o Estado civil");
    } else {
      setAlertAge("");
    }
    if (!education.value) {
      formIsValid = false;
      setAlertEducation("É preciso preencher a escolaridade");
    } else {
      setAlertAge("");
    }
    if (!meds.value) {
      formIsValid = false;
      setAlertMeds("É preciso preencher as medicações em uso");
    } else {
      setAlertAge("");
    }
    if (!sex) {
      formIsValid = false;
      setAlertSex("É preciso marcar um Sexo");
    } else {
      setAlertSex("");
    }
    if (!date.value) {
      formIsValid = false;
      setAlertDate("É preciso uma Data");
    } else {
      setAlertDate("");
    }
    return formIsValid;
  }

  function handleSubmit() {
    let resUser = {
      id: userData.id,
      name: name.value,
      email: email.value,
      age: age.value,
      sex: sex,
      social: civil.value,
      education: education.value,
      meds: meds.value,
      group: 1,
      phase: 1,
      DASS: "",
      DASSdep: 0,
      DASSanx: 0,
      DASSstr: 0,
      WordVal: [],
      Trial1: [],
      Trial1attempts: 0,
      Trial1score: 0,
      Test1: [],
      Test1score: 0,
      Trial2: [],
      Trial2attempts: 0,
      Trial2score: 0,
      Test2: [],
      Test2score: [],
      sessionTime: sessionTime,
    };

    userData.research = "wordMood";
    userData.sex = sex;
    userData.age = age.value;
    userData.social = civil.value;
    userData.education = education.value;

    setUserData(userData);
    updateUserFirebase(userData);
    setUserResearch(resUser);
    createResearchFirebase(resUser);
    navigate("/");
  }

  if (!userData) {
    return <div>Loading...</div>;
  } else {
    if (index === 0) {
      return (
        <section className={css.container}>
          <Head title="Research" />
          <h1>Termo de Consentimento Livre e Esclarecido</h1>
          <p>
            {userData.displayName}, você está sendo convidado(a) a participar
            como voluntário(a), da pesquisa “Efeito de relações derivadas de
            similar e oposto e de sinônimos e antônimos em relatos verbais” a
            ser desenvolvido pelo pesquisador Thiago Neves Rocha, sob a
            coordenação da Profa Dra. Raquel Maria de Melo, do Departamento de
            Processos Psicológicos Básicos (PPB), do Instituto de Psicologia da
            Universidade de Brasília (IP/UnB).
          </p>
          <p>
            Será investigado o efeito das relações entre palavras e emoções
            associadas à depressão. Durante o estudo serão realizadas tarefas
            informatizadas que envolvem: (1) avaliar a valência emocional de
            diferentes palavras apresentadas em tela, com uma escala tipo Likert
            de cinco pontos; (2) apresentação de diferentes palavras na tela do
            computador; (3) selecionar e arrastar, com o uso do mouse, uma das
            palavras dispostas na tela; (4) confirmar a palavra escolhida como
            correta; (5) apresentação de informações sobre o desempenho
            (resposta correta ou incorreta) após resposta de seleção ou mudança
            para uma nova tela com estímulos após um determinado período de
            tempo. Esses procedimentos e materiais já foram utilizados em outros
            estudos e não implicam em riscos à saúde além daqueles aos quais se
            está exposto em qualquer outra situação de aprendizagem via
            computador. Durante a realização das tarefas pode ocorrer algum
            nível de cansaço ou desconforto. Para minimizar esses riscos, será
            garantido local reservado para o procedimento, liberdade para não
            responder questões constrangedoras e para solicitar a interrupção da
            atividade caso se sinta desconfortável. Se necessário, você receberá
            assistência imediata.
          </p>
          <p>
            Será utilizada uma das salas de coleta de dados do Instituto de
            Psicologia da Universidade de Brasília. Está previsto um encontro
            com duração aproximada de uma hora, sendo que a duração dos
            encontros poderá aumentar ou diminuir dependendo do desempenho na
            tarefa. O pesquisador responsável estará presente para acompanhar o
            procedimento, dar assistência e esclarecer dúvidas.
          </p>
          <p>
            Os dados sobre o procedimento realizado serão coletados no ambiente
            virtual Firebase do Google (https://firebase.google.com), e serão
            mantidos durante a realização da pesquisa. Após o término da
            pesquisa, os dados serão salvos em um computador e excluídos do
            ambiente virtual. Apenas o pesquisador principal terá acesso à
            plataforma com os dados do estudo por meio de senha criptografada e
            os riscos envolvidos na violação da segurança são os mesmos de
            outras plataformas virtuais como email.
          </p>
          <p>
            Serão fornecidos todos os esclarecimentos necessários sobre os
            objetivos do estudo, o tipo de tarefa e como realizá-la antes e no
            decorrer da pesquisa. Além disso, na publicação dos resultados do
            estudo (e.g., em reuniões de pesquisa, eventos científicos,
            publicações) será mantido o sigilo sobre a sua identidade e somente
            os integrantes da pesquisa terão acesso aos dados pessoais.
          </p>
          <p>
            A sua participação é voluntária (não haverá a oferta de nenhuma
            recompensa em dinheiro pela sua participação). A qualquer momento,
            você poderá solicitar a interrupção da tarefa no computador,
            cancelar a participação no estudo por meio de comunicação ao
            pesquisador responsável sobre a decisão, ou se recusar a responder
            qualquer questão que lhe traga constrangimento.
          </p>
          <p>
            Os resultados poderão ser apresentados em congressos científicos e
            será mantido o sigilo de todos os seus dados pessoais. Caso você
            necessite obter informações sobre a pesquisa, poderá́ fazê-lo
            entrando em contato com o Pesquisador responsável do estudo, que
            ficará com a guarda dos dados e dos materiais utilizados na
            pesquisa.
          </p>
          <p>
            Esclarecimentos poderão ser feitos a qualquer momento da pesquisa
            por meio de contato com o pesquisador responsável Thiago Neves
            Rocha, por meio do e-mail, 222113427@aluno.unb.br. Informações sobre
            a aprovação dessa pesquisa podem ser obtidas no Comitê de Ética em
            Pesquisa em Ciências Humanas e Sociais - CEP/CHS; cep_chs@unb.br;
            Fone: (61) 3107-1592.
          </p>
          <p>
            Este projeto foi revisado e aprovado pelo Comitê de Ética em
            Pesquisa em Ciências Humanas e Sociais - CEP/CHS. As informações com
            relação à assinatura do TCLE ou os direitos do sujeito da pesquisa
            podem ser obtidas através do e-mail do CEP/CHS.
          </p>
          <p>
            Este documento encontra-se redigido em duas vias, sendo uma para o
            participante e outra para o pesquisador.
          </p>
          <label className={css.confirmation}>
            Declaro que li e aceito o termo de Consentimento.
            <input
              type="checkbox"
              checked={confirmation}
              onChange={() => {
                setConfirmation(!confirmation);
              }}
            />
            <span className={css.checkmark}></span>
          </label>
          <Button disabled={!confirmation} onClick={() => nextPage()}>
            Submeter
          </Button>
        </section>
      );
    } else if (index === 1) {
      return (
        <section className={css.container}>
          <Head title="Research" />

          <div ref={ref}></div>

          <h1>Questionário</h1>
          {/* <form onSubmit={handleValidation}> */}
          <form>
            <Input
              label="Nome completo:"
              value={userData.displayName}
              {...name}
            />
            <Input
              label="Email:"
              required={true}
              value={userData.email}
              {...email}
            />
            <Input label="Idade:" required={true} {...age} />
            <Input label="Estado civil:" required={true} {...civil} />
            <Input label="Escolaridade:" required={true} {...education} />
            <Input
              label="Medicações em uso (nome e dosagem):"
              required={true}
              {...meds}
            />
            <h4>Sexo:</h4>
            <label className={css.confirmation}>
              Masculino
              <input
                type="checkbox"
                checked={sex === "masculino" ? true : false}
                onChange={() => {
                  setSex("masculino");
                }}
              />
              <span className={css.checkmark}></span>
            </label>
            <label className={css.confirmation}>
              Feminino
              <input
                type="checkbox"
                checked={sex === "feminino" ? true : false}
                onChange={() => {
                  setSex("feminino");
                }}
              />
              <span className={css.checkmark}></span>
            </label>
            <label className={css.confirmation}>
              Prefiro não declarar
              <input
                type="checkbox"
                checked={sex === "no" ? true : false}
                onChange={() => {
                  setSex("no");
                }}
              />
              <span className={css.checkmark}></span>
            </label>
            <Input label="Data:" required={true} {...date} />
            <label className={css.confirmation}>
              Declaro que desejo participar da pesquisa
              <input
                type="checkbox"
                checked={confirmation}
                onChange={() => {
                  setConfirmation(!confirmation);
                }}
              />
              <span className={css.checkmark}></span>
            </label>
            {alertName && <p className={css.error}>{alertName}</p>}
            {alertEmail && <p className={css.error}>{alertEmail}</p>}
            {alertAge && <p className={css.error}>{alertAge}</p>}
            {alertCivil && <p className={css.error}>{alertCivil}</p>}
            {alertEducation && <p className={css.error}>{alertEducation}</p>}
            {alertMeds && <p className={css.error}>{alertMeds}</p>}
            {alertSex && <p className={css.error}>{alertSex}</p>}
            {alertDate && <p className={css.error}>{alertDate}</p>}
            <Button
              disabled={!confirmation}
              onClick={(e) => {
                e.preventDefault();
                handleValidation() && handleSubmit();
              }}
            >
              Submeter
            </Button>
          </form>
        </section>
      );
    }
  }
};
